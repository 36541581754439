import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../config";

export const VideoLogs = ({
  videoUrl: propVideoUrl,
  logUrl: propLogUrl,
  websiteId: propWebsiteId,
  footprintId: propFootprintId,
  videoId: propVideoId,
}) => {
  const location = useLocation();

  // Access passed state data (renaming to avoid conflict)
  const {
    videoUrl: stateVideoUrl,
    logUrl: stateLogUrl,
    run,
    websiteId: stateWebsiteId,
    footprintId: stateFootprintId,
    videoId: stateVideoId,
  } = location.state || {};

  // Use state values if available, otherwise fallback to props
  const finalVideoUrl = stateVideoUrl || propVideoUrl;
  const finalLogUrl = stateLogUrl || propLogUrl;
  const finalWebsiteId = stateWebsiteId || propWebsiteId;
  const finalFootprintId = stateFootprintId || propFootprintId;
  const finalVideoId = stateVideoId || propVideoId;

  console.log(
    "finalVideoId",
    finalVideoId,
    "finalFootprintId",
    finalFootprintId,
    "finalWebsiteId",
    finalWebsiteId,
    "finalVideoId",
    finalVideoId
  );
  // const [logs, setLogs] = useState("");
  const [summaryLogs, setSummaryLogs] = useState("");
  const [summary, setSummary] = useState("");
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [video, setVideo] = useState();
  const [actionLog, setActionLog] = useState();

  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [logs, setLogs] = useState([]);

  // Function to fetch and parse log data from S3
  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await fetch(finalLogUrl);
        const text = await response.text();
        const logLines = text.split("\n").filter(Boolean); // Split log by lines and filter out empty lines

        const parsedLogs = logLines.map((log) => {
          const match = log.match(/\[(\d+)ms\]/); // Extract the milliseconds from the log string
          const milliseconds = match ? parseInt(match[1], 10) : 0;
          const content = log.split(" - ").slice(2).join(" - "); // Extract log content after milliseconds
          return { milliseconds, content };
        });
        setLogs(parsedLogs);
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchLogs();
  }, [finalLogUrl]);

  // Function to handle time updates from the video
  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (video) {
      setCurrentTime(video.currentTime * 1000); // Convert video time to milliseconds
    }
  };

  // Play/Pause Video
  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Seek video to a specific time
  const seekTo = (time) => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = time / 1000; // Convert milliseconds to seconds
    }
  };

  // Get the logs that should be visible based on the video time
  const getVisibleLogs = () => {
    return logs?.filter((log) => currentTime >= log.milliseconds);
  };

  const visibleLogs = getVisibleLogs();

  const fetchVideo = async () => {
    try {
      const response = await axios.get(`${baseUrl}/videos/${finalVideoId}`);

      console.log("Get request for video successful!", response.data);
      if (response?.data?.summaryUrl) {
        await fetchSummariseLogs(response?.data?.summaryUrl);
      }
      setVideo(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const handleSummarizeLogs = async () => {
    setIsSummarizing(true); // Set loading state
    const userId = localStorage.getItem("userId");
    // logs, userId, websiteId, footprintId, videoId
    try {
      const response = await axios.post(`${baseUrl}/ai/summary`, {
        logs: actionLog,
        userId,
        websiteId: finalWebsiteId,
        footprintId: finalFootprintId,
        videoId: finalVideoId,
      });

      // Assuming the API returns the summary in the response
      setSummary(response.data.summary);
      setIsSummarizing(false); // Unset loading state
      setIsHide(true);
      window.location.reload();
    } catch (error) {
      console.error("Error summarizing logs:", error);
      setSummary("Failed to summarize logs.");
      setIsSummarizing(false); // Unset loading state
    }
  };

  const fetchActionLogs = async () => {
    try {
      const response = await fetch(finalLogUrl);
      console.log(response);
      const logText = await response.text();
      setActionLog(logText);
    } catch (error) {
      console.error("Error fetching logs:", error);
      setActionLog("Failed to load logs.....");
    }
  };

  const fetchSummariseLogs = async (logs) => {
    try {
      const response = await fetch(logs);
      console.log("response from sumamry Url", response);
      const summaryLogs = await response.text();
      setSummaryLogs(summaryLogs);
    } catch (error) {
      console.error("Error fetching logs:", error);
      setLogs("Failed to load logs.....");
    }
  };

  // console.log(logs, finalLogUrl, "hii data");
  useEffect(() => {
    fetchVideo();
    fetchActionLogs();
  }, [finalLogUrl, finalVideoId]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(logs)
      .then(() => {
        alert("Logs copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy logs: ", err);
      });
  };

  const copyToSummary = () => {
    navigator.clipboard
      .writeText(summaryLogs)
      .then(() => {
        alert("Logs copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy logs: ", err);
      });
  };

  return (
    <>
      <div className="header-section">
        <div className="info">
          <div className="setup-guide mt-5 flex gap-4 justify-between">
            <div className="inner-setup-guide flex flex-col gap-6 text-color-gray-light w-8/12 flex-1">
              <div className="container flex-1">
                <div className="wrapper w-full h-full border rounded-2xl flex flex-col bg-white">
                  <div className="flex-grow">
                    <video
                      ref={videoRef}
                      className="w-full h-full object-cover rounded-md"
                      controls
                      src={finalVideoUrl && finalVideoUrl}
                      onTimeUpdate={handleTimeUpdate}
                      alt="Test Case Video"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="right-inner-setup-guide text-color-gray-light w-4/12 flex flex-col gap-4 flex-1">
              <div className="container flex-1">
                <div className="wrapper w-full h-full border rounded-2xl bg-white">
                  <h2 className="text-xl font-semibold p-4 text-gray-800">
                    Summary Logs
                    <button onClick={copyToSummary} className="ml-2">
                      <i className="fa-solid fa-copy"></i>
                    </button>
                  </h2>
                  <div className="p-4 h-60  custom-scrollbar overflow-y-auto">
                    <pre className="text-sm text-gray-700 whitespace-pre-wrap">
                      {summaryLogs ? (
                        summaryLogs
                      ) : (
                        <div className="flex justify-center items-center">
                          <button
                            onClick={handleSummarizeLogs}
                            className="mt-4 bg-green-500 text-white py-2 px-4 my-auto rounded hover:bg-green-600"
                          >
                            {isSummarizing
                              ? "Summarizing..."
                              : "Summarize Logs"}
                          </button>
                        </div>
                      )}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="setup-guide mt-5 flex justify-between gap-4">
            <div className="wrapper w-full  h-auto min-h-[58vh] border rounded-2xl flex flex-col bg-white">
              <div className="flex-grow">
                {logs && (
                  <div className="custom-scrollbar p-4 h-full overflow-y-auto">
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">
                      Action Logs
                      <button onClick={copyToClipboard} className="ml-2">
                        <i className="fa-solid fa-copy"></i>
                      </button>
                    </h2>
                    <pre className="text-sm text-gray-700 whitespace-pre-wrap">
                      {visibleLogs.map((log, index) => (
                        <div
                          key={index}
                          className={`log-entry ${
                            currentTime >= log.milliseconds ? "highlighted" : ""
                          }`}
                          onClick={() => seekTo(log.milliseconds)}
                        >
                          {log.content}
                        </div>
                      ))}
                    </pre>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="wrapper w-[77.6vw] h-auto min-h-[58vh] border rounded-xl flex flex-col bg-white">
              <div className="flex-grow">
                {logs && (
                  <div className="p-4 h-full">
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">
                      Action Logs
                      <button onClick={copyToClipboard} className="ml-2">
                        <i className="fa-solid fa-copy"></i>
                      </button>
                
                    </h2>
                    <div className="custom-scrollbar max-h-[40vh] overflow-y-auto">
                      <pre className="text-sm text-gray-700 whitespace-pre-wrap">
                        {visibleLogs.map((log, index) => (
                          <div
                            key={index}
                            className={`log-entry ${
                              currentTime >= log.milliseconds
                                ? "highlighted"
                                : ""
                            }`}
                            onClick={() => seekTo(log.milliseconds)}
                          >
                            {log.content}
                          </div>
                        ))}
                      </pre>
                    </div>
                  </div>
                )}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
