import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import axios from "axios";
import { useParams } from "react-router-dom";
import { baseUrl } from "../config";
const Video = () => {
  const [video, setVideo] = useState();
  const { videoId } = useParams();

  const fetchVideo = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/videos/${videoId}`
      );

      console.log("Get request successful!", response.data);
      setVideo(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };
 
  useEffect(() => {
    if (videoId) {
      fetchVideo();
    }
  }, [videoId]);

  return (
    <>
      <Sidebar />
      <div className="grow ml-16 md:ml-64 h-full lg:h-screen bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-white">
        <Navbar />
        <div className="flex flex-col justify-center items-center space-y-8">
          <ReactPlayer
            url={video && video.url}
            width="100%"
            height="100%"
            controls
            playing
          />
        </div>
      </div>
    </>
  );
};

export default Video;
