import React from "react";
import Popup_End_Card from "./Card/Popup_End_Card";
import { useLocation } from "react-router-dom";

const SuccessFootprint = () => {
  const location = useLocation();
  console.log("hittt from scueess popup");
  // Access the passed state data
  const { websiteId } = location.state || {};
  console.log("websiteId from successfoot", websiteId);
  return (
    <>
      <div className="fixed inset-0 bg-black opacity-50 z-40"></div>{" "}
      {/* Overlay */}
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <Popup_End_Card websiteId={websiteId} />
      </div>
    </>
  );
};

export default SuccessFootprint;
