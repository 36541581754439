// import React, { useState } from 'react';
// import { Table } from 'antd';

// const columns = [
//   {
//     title: 'Run',
//     dataIndex: 'run',
//     key: 'run',
//     render: (text) => <span className="font-bold text-color-gray-light">{text}</span>,
//   },
//   {
//     title: 'Date & Time',
//     dataIndex: 'date',
//     key: 'date',
//     render: (text) => (
//       <div className="text-sm text-black">
//         <div>{text.date}</div>
//         <div className="text-xs text-gray-500">{text.day}</div>
//       </div>
//     ),
//   },
//   {
//     title: 'Status',
//     dataIndex: 'status',
//     key: 'status',
//     render: (text) => (
//       <span className={`text-sm font-medium ${text === 'Pass' ? 'text-green-600' : 'text-red-600'}`}>
//         {text}
//       </span>
//     ),
//   },
//   {
//     title: 'Records',
//     dataIndex: 'records',
//     key: 'records',
//     render: (text) => (
//       <span className="text-blue-500 font-semibold hover:underline cursor-pointer">{text}</span>
//     ),
//   },
// ];

// const dataSource = [
//   {
//     key: '1',
//     run: 'Run #26',
//     date: { date: 'February 14, 2020', day: 'Friday' },
//     status: 'Pass',
//     records: 'View Run',
//     videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4',
//   },
//   {
//     key: '2',
//     run: 'Run #27',
//     date: { date: 'February 15, 2020', day: 'Saturday' },
//     status: 'Pass',
//     records: 'View Run',
//     videoUrl: 'https://www.w3schools.com/html/movie.mp4',
//   },
//   {
//     key: '3',
//     run: 'Run #28',
//     date: { date: 'February 16, 2020', day: 'Sunday' },
//     status: 'Fail',
//     records: 'View Run',
//     videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4',
//   },
// ];

// const TableModel = () => {
//   const [expandedRowKeys, setExpandedRowKeys] = useState([]);

//   const onExpand = (expanded, record) => {
//     const keys = expanded ? [record.key] : [];
//     setExpandedRowKeys(keys);
//   };

//   const expandedRowRender = (record) => {
//     return (
//       <div className="m-[-15px]">
//         <video width="100%" controls>
//           <source src={record.videoUrl} type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//       </div>
//     );
//   };

//   return (
//     <div className="p-2 mx-2">
//       <Table
//         columns={columns}
//         dataSource={dataSource}
//         className="custom-table"
//         pagination={false}
//         rowClassName={(record, index) => (index === 2 ? 'bg-[#FBE5C9]' : '')}
//         expandable={{
//           expandedRowRender,
//           expandedRowKeys,
//           onExpand,
//           expandRowByClick: true,
//         }}
//         components={{
//           header: {
//             cell: (props) => (
//               <th
//                 {...props}
//                 className="!bg-transparent !text-[#E0E0E0] !font-semibold"
//               />
//             ),
//           },
//         }}

//         expandIconColumnIndex={-1} // This will remove the expand icon

//       />
//     </div>
//   );
// };

// export default TableModel;
import React, { useState, useEffect } from "react";
import { Table } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../config";
// Function to format date and time
const formatDateTime = (isoString) => {
  const date = new Date(isoString);

  const options = { month: "short", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };
  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

  const dayName = date.toLocaleDateString("en-US", { weekday: "long" });

  return {
    date: `${formattedDate}, at ${formattedTime}`,
    dayName,
  };
};

const columns = [
  {
    title:  <span  className="text-gray-header">Run</span>,
    dataIndex: "run",
    key: "run",
    render: (text) => (
      <span className="font-bold text-color-gray-light">{text}</span>
    ),
  },
  {
    title: <span  className="text-gray-header">Date & Time</span>,
    dataIndex: "date",
    key: "date",
    render: (text) => (
      <div className="text-sm text-black">
        <div>{text.date}</div>
        {/* <div className="text-xs text-gray-500">{text.dayName}</div> */}
      </div>
    ),
  },
  {
    title: <span  className="text-gray-header">Result</span>,
    dataIndex: "status",
    key: "status",
    render: (text) => (
      <span
        className={`text-sm font-medium ${
          text === "Pass" ? "text-green-600" : "text-red-600"
        }`}
      >
        {/* {text === "Pass" ? "Pass" : "Fail"}{" "} */}
        {text}
       {/* {text=== "success" ? "Pass" : text==="partial_success"? "Partial Success" : text==="partial_failure" ? "Partial Failed" : "Fail" } */}
      </span>
    ),
  },
  {
    title:  <span  className="text-gray-header">Records</span>,
    dataIndex: "records",
    key: "records",
    render: (text) => (
      <span className="text-blue-500 font-semibold hover:underline cursor-pointer">
        {text}
      </span>
    ),
  },
];

const TableModel = ({ footprintId, websiteId }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const navigate = useNavigate();
  console.log("footprintId", footprintId);
  const [limitedDataSource, setLimitedDataSource] = useState([]);

  useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      setLimitedDataSource(dataSource.slice(0, 4));
    }
  }, [dataSource]);
  useEffect(() => {
   

    const fetchData = async () => {
      const response = await axios.get(
        `${baseUrl}/footprint/foot/${footprintId}`
      ); // Adjust the API endpoint
      const footprint = response.data;

      // Map the footprint data to the table structure
      const data = footprint?.videos?.map((video, index) => {
        const { date, dayName } = formatDateTime(video.createdAt);
        return {
          key: video?._id,
          run: `Run #${index + 1}`,
          date: { date, dayName },
          status: video.status === "success" ? "Pass" : "Fail",

          // status: video?.status === "success" ? "Pass" : video?.status==="partial_success"? "Partial Success" : video?.status==="partial_failure" ? "Partial Failed" : "Fail",
          records: "View Run",
          videoUrl: video?.url,
          logUrl: video?.logUrl,
        };
      });

      setDataSource(data);
    };
    fetchData();
  }, []);

  const handleClick = (record) => {
    console.log("record--------------------------------", record);
    const data = {
      videoUrl: record.videoUrl,
      logUrl: record.logUrl,
      run: record.run,
      websiteId: websiteId,
      footprintId: footprintId,
      videoId: record.key,
      date : record.date
    };
    navigate(`/logs`, { state: data });
  };

  const onExpand = (expanded, record) => {
    const keys = expanded ? [record.key] : [];
    setExpandedRowKeys(keys);
  };

  const expandedRowRender = (record) => {
    return (
      <div className="m-[-15px]">
        <video width="100%" controls>
          <source src={record?.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };

  return (
    <div className="p-2 mx-2">
      <Table
        columns={columns}
        // dataSource={dataSource}
        dataSource={limitedDataSource}
        className="custom-table"
        pagination={false}
        rowClassName={(record, index) =>
          record.status === "Fail" ? "bg-[#FCD0CF] bg-opacity-50" : ""
        }

        expandable={{
          expandedRowRender,
          expandedRowKeys,
          onExpand,
          expandRowByClick: true,
        }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                className="!bg-transparent !text-color-gray-light !font-semibold"
              />
            ),
          },
        }}
        expandIconColumnIndex={-1} // This will remove the expand icon
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              // Handle the click event
              // console.log("Row clicked :", record, rowIndex);
              handleClick(record);
            },
          };
        }}
      />
      {dataSource && dataSource.length > 4 && (
        <div className="flex items-center justify-center p-4 bg-white">
          <Link to="/footprint-info-list" state={{ footprintId, websiteId }}>
            <button
              // onClick={handleViewMore}
              className="button bg-button-bg text-white text-xs rounded-lg p-2 flex items-center space-x-2"
            >
              <span>View More</span>
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default TableModel;
