import React, { useState, useEffect } from "react";
import { Table, Pagination, Checkbox } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import axios from "axios";
import SuccessToast from "../Toast/SuccessToast";

import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../config";
import FailToast from "../Toast/FailToast";

// Custom pagination component
const CustomPagination = ({
  current,
  pageSize,
  total,
  onChange,
  onPageSizeChange,
}) => {
  return (
    <div className="flex items-center justify-between p-4">
      <div className="text-sm text-gray-700">
        Showing{" "}
        <b>
          {(current - 1) * pageSize + 1}-{Math.min(current * pageSize, total)}
        </b>{" "}
        of {total} records
      </div>
      <div className="flex items-center space-x-2">
        <select
          value={pageSize}
          onChange={(e) => onPageSizeChange(parseInt(e.target.value, 10))}
          className="rounded px-2 py-1 text-sm"
        >
          <option value={10}>Show 10 rows</option>
          <option value={20}>20 rows</option>
          <option value={50}>50 rows</option>
        </select>
        <Pagination
          current={current}
          pageSize={pageSize}
          total={total}
          onChange={onChange}
          hideOnSinglePage
          showSizeChanger={false}
          prevIcon={
            <div className="bg-[#E4E4E4] text-white rounded-md">
              <LeftOutlined />
            </div>
          }
          nextIcon={
            <div className="bg-[#E4E4E4] text-white rounded-md">
              <RightOutlined />
            </div>
          }
          className="ant-pagination"
          itemRender={(current, type, originalElement) => {
            if (type === "prev") {
              return (
                <div className="bg-[#E4E4E4] text-black rounded-md">
                  <LeftOutlined />
                </div>
              );
            }
            if (type === "next") {
              return (
                <div className="bg-[#E4E4E4] text-black rounded-md">
                  <RightOutlined />
                </div>
              );
            }
            return "";
          }}
        />
      </div>
    </div>
  );
};



const Footprint_All_Status_Table = ({
  selectedTags,
  selectedStatus,
  websiteId,
}) => {
  const navigate = useNavigate();
  const [activeFail, setActiveFail] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [value, setValue] = useState("");

  console.log(selectedTags, "new pringint")

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [dataSource, setDataSource] = useState([]);
  const [filters, setFilters] = useState({
    tags: selectedTags,
    status: selectedStatus,
  });


  
  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, current: page }));
  };

  const handlePageSizeChange = (size) => {
    setPagination((prev) => ({ ...prev, pageSize: size, current: 1 }));
  };

  const pauseSchedule = async (record) => {
    try {
      const response = await axios.post(`${baseUrl}/schedule/pause`, {
        footprintId: record.key,
      });
      console.log("API response for pauseSchedule:", response.data);
      setActiveSuccess(true);
      setValue(response.data.msg);
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  const resumeSchedule = async (record) => {
    const token = localStorage.getItem("Token");
    if (token) {
      try {
        // const response = await axios.post(`${baseUrl}/schedule/resume`, {
        //   footprintId: record.key,
        // });
        const response = await axios.post(
          `${baseUrl}/schedule/resume`,
          { footprintId: record.key },
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );
        console.log("API response for resumeSchedule:", response.data);
        setActiveSuccess(true);
        setValue(response.data.message);
      } catch (error) {
        console.error("Error making API call:", error);
      }
    }
  };
  const handleActionClick = async (record) => {
    if (record.status === "active") {
      console.log("record for stop button", record);
      pauseSchedule(record);
    }
    if (record.status === "stopped") {
      console.log("record for stop button", record);
      resumeSchedule(record);
    }
  };
  const handleRowClick = (record, rowIndex) => {
    console.log("Row clicked for normal columns:", record, rowIndex);
    const data = { footprintId: record.key, websiteId: websiteId };
    record.status==="inactive" ? navigate(`/Savefootprint/${record.key}/website/${websiteId}` ,{ state: { Back: false } }) :     navigate(`/footprint-list`, { state: data });

    // navigate(`/footprint-list`, { state: data });
  };

  useEffect(() => {
    getAllFootprintsData();
  }, [filters]);

  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 3000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
        const data = { websiteId: websiteId };
        navigate("/footprint-status-list", { state: data });
      }, 2000);
    }
  }, [activeFail, activeSuccess]);
  // Define columns with the new headers and checkbox
  const columns = [
    {
      title: <span  className="text-gray-header">Test #</span>,
      dataIndex: "footprint",
      key: "footprint",
      render: (_, record) => (
        <div className="flex items-center">
          {/* <Checkbox
            checked={record.selected}
            onChange={(e) => handleCheckboxChange(record.key, e.target.checked)}
            className="mr-2"
          /> */}
          <span className="font-bold text-color-gray-light">
            {record.footprint}
          </span>
        </div>
      ),
    },
    {
      title: <span  className="text-gray-header">Test Name</span>,
      dataIndex: "fpName",
      key: "fpName",
      render: (text) => <span className="text-sm text-gray-700">{text}</span>,
      sorter: (a, b) => a.fpName.localeCompare(b.fpName), // Sort by Test Name

    },
    {
      title:  <span  className="text-gray-header">Created Date & Time</span>,
      dataIndex: "createdDateTime",
      key: "createdDateTime",
      render: (text) => (
        <div className="text-sm text-black">
          <div>{text.date}</div>
          {/* <div className="text-xs text-gray-500">{text.dayName}</div> */}
        </div>
      ),
    },
    {
      title:  <span  className="text-gray-header">Last Run Date & Time</span>,
      dataIndex: "lastRun",
      key: "lastRun",
      render: (text) => (
        <div className="text-sm text-black">
          <div>{text.date}</div>
          {/* <div className="text-xs text-gray-500">{text.dayName}</div> */}
        </div>
      ),
    },
    {
      title: <span  className="text-gray-header">Status</span>,
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <div className="flex  items-center space-x-2">
          {text === "active" ? (
            <svg width="12" height="12" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5C16 12.9183 12.4183 16.5 8 16.5Z" fill="#2AB930" />
            </svg>

          ) : text === "complete" ? (
            <svg width="12" height="12" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5C16 12.9183 12.4183 16.5 8 16.5Z" fill="#ED9526" />
            </svg>

          ) : (
            <svg width="12" height="12" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5C16 12.9183 12.4183 16.5 8 16.5Z" fill="#414141" />
            </svg>

          )}
          <span
            className={`text-sm font-medium ${text === "active"
              ? "text-green-600"
              : text === "complete"
                ? "text-[#ED9526]"
                : "text-color-gray-light"
              }`}
          >
            {text === "active"
              ? "Active"
              : text === "inactive"
                ? "Draft"
                : text === "stopped"
                  ? "Paused"
                  : "Completed"}{" "}
          </span>
        </div>
      ),
    },
    {
      title:  <span  className="text-gray-header">Successful Runs</span>,
      dataIndex: "successfulRuns",
      key: "successfulRuns",
      render: (text) => (
        <div className="flex items-center">
          <span className="font-semibold text-center">{text}</span>
        </div>
      ),
    },
    {
      title:  <span  className="text-gray-header">Failed Runs</span>,
      dataIndex: "failedRuns",
      key: "failedRuns",
      render: (text) => (
        <div className="flex  items-center">
          <span className="font-semibold text-center">{text}</span>
        </div>
      ),
    },
    {
      title: <span   className="text-gray-header">Action</span>,

      render: (_, record) => (
        <div
          className="flex items-center justify-center cursor-pointer"
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click event
            handleActionClick(record);
          }}
        >
          {/* {record.status === "inactive" && <i class="fa-solid fa-play"></i>} */}
          {record.status === "stopped" && <i class="fa-solid fa-play"></i>}
          {record.status === "complete" && <i class="fa-solid fa-check"></i>}
          {record.status === "active" && <i class="fa-solid fa-pause"></i>}
        </div>
      ),
    },
  ];

  // Handle checkbox changes
  const handleCheckboxChange = (key, checked) => {
    // Update the state or perform necessary actions
    console.log(`Row ${key} checkbox changed to ${checked}`);
  };

  const getAllFootprintsData = async () => {
    try {
      // Construct query parameters based on tags and status
      let queryParams = "";
      if (filters.tags.length > 0 || filters.status) {
        console.log("mai enter hua ");
        const tagsParam =
          filters.tags.length > 0 ? `tags=${filters.tags.join(",")}` : "";
        const statusParam = filters.status ? `status=${filters.status}` : "";
        queryParams = `?${[tagsParam, statusParam].filter(Boolean).join("&")}`;
      }

      // Make the API call with query parameters if present
      const response = await axios.get(
        `${baseUrl}/footprint/${websiteId}${queryParams}`
      );

      console.log("DATA", response.data);
      console.log("Filters", filters);

      // Set the filtered data in the state
      setDataSource(formatData(response.data));
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    // Update filters by preserving the previous state and updating the tags
    setFilters((prevFilters) => ({
      ...prevFilters,
      tags: selectedTags,
      status: selectedStatus,
    }));
  }, [selectedTags, selectedStatus]);

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);

    const options = { month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    const dayName = date.toLocaleDateString("en-US", { weekday: "long" });

    return {
      date: `${formattedDate}, at ${formattedTime}`,
      dayName,
    };
  };

  const formatData = (data) => {
    return data.map((item, index) => {
      const createdDateTime = formatDateTime(item.createdAt);
      const lastRunDateTime = item.lastRunAt
        ? formatDateTime(item.lastRunAt)
        : { date: "N/A", dayName: "" };
      const totalRuns = item.successCount + item.failureCount;
      // const successPercentage = (item.successCount / totalRuns) * 100;
       // const failurePercentage = (item.failureCount / totalRuns) * 100;
      
      const successPercentage = Math.floor((item.successCount / totalRuns) * 100);
      const failurePercentage = Math.floor((item.failureCount / totalRuns)*100)
     
     

      return {
        key: item._id,
        footprint: `TT #${index + 1}`,
        fpName: item.name,
        createdDateTime: createdDateTime,
        lastRun: lastRunDateTime,
        status: item.status,
        successfulRuns:
          successPercentage !== undefined && !isNaN(successPercentage)
            ? `${successPercentage}%`
            : "--",
        failedRuns:
          failurePercentage !== undefined && !isNaN(failurePercentage)
            ? `${failurePercentage}%`
            : "--",
        selected: false,
      };
    });
  };

  // Filter data based on tags, status, or both
  const filterData = (data, { tags, status }) => {
    return data.filter((item) => {
      const matchesTags = tags ? item.tags.includes(tags) : true;
      const matchesStatus = status ? item.status === status : true;
      return matchesTags && matchesStatus;
    });
  };

  // Handle filter changes
  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  useEffect(() => {
    getAllFootprintsData();
  }, [filters, websiteId]);

  return (
    <div className="p-2 mx-2">
      {activeSuccess && (
        <SuccessToast
          successValue={value}
          activeSuccess={activeSuccess}
          setActiveSuccess={setActiveSuccess}
        />
      )}
      {activeFail && (
        <FailToast
          errorValue={value}
          activeFail={activeFail}
          setActiveFail={setActiveFail}
        />
      )}
      <Table
        columns={columns}
        // dataSource={dataSource}
        dataSource={dataSource.slice(
          (pagination.current - 1) * pagination.pageSize,
          pagination.current * pagination.pageSize
        )}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              // console.log("Row clicked:", record, rowIndex);
              // const data = { footprintId: record.key, websiteId: websiteId };
              // navigate(`/footprint-list`, { state: data });
              // Perform any action here, like navigating to another page or opening a modal
              handleRowClick(record, rowIndex);
            },
          };
        }}
        pagination={false}
        // rowClassName={(record, index) => (index % 2 === 0 ? 'bg-[#FBE5C9]' : '')}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                className="!bg-transparent !text-color-gray-light !font-semibold"
              />
            ),
          },
        }}
      />

    {
      dataSource?.length >10 && (   <CustomPagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={dataSource.length}
        onChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />)
    }
    </div>
  );
};

export default Footprint_All_Status_Table;
