import React, { useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import AddSite from "./Modal/AddSite";

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const username = localStorage.getItem("username");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="bg-gray-100  text-gray-900 border-b border-gray-300 p-2 flex justify-between items-center dark:border-gray-600 dark:bg-gray-900 dark:text-white">
      <div className="flex flex-row justify-center items-center space-x-2 pl-5">
        <h1 className="text-lg">Welcome</h1>
        <h1 className="text-lg">{username}</h1>
      </div>

      <button
        onClick={openModal}
        class="bg-gray-300 hover:bg-gray-400 text-color-gray-light font-bold py-2 px-4 rounded inline-flex items-center"
      >
        <IoAddOutline />
        <span className="text-sm pl-2 text-gray-500">Add Sites</span>
      </button>

      <AddSite isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default Navbar;
