

import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const Detail = ({ formData, setFormData }) => {
  const [phoneError, setPhoneError] = useState("");
  // const handleChange = (event) => {
  //   const { name, value } = event.target;

  //   setFormData({ ...formData, [name]: value });
  //   console.log("formData", formData);
  // };

  const validatePhoneNumber = (value, country) => {
    // Remove non-numeric characters (like + and spaces)
    
   

    const cleanedNumber = value.replace(/\D/g, "");

    // Assuming we're validating for US phone numbers (10 digits)
    console.log("phonenumber", cleanedNumber.length)
    if (cleanedNumber.length >=10 ) {

      setPhoneError(""); // Clear error if valid
      return true;
    } else {
      setPhoneError("Please enter a valid  phone number.");
      return false;
    }
  };




  const handlePhoneChange = (value, country) => {
    setFormData({ ...formData, mobile: value });
    console.log("formData", formData, "Country", country);
    validatePhoneNumber(value, country);
  };



  return (
    <>
      <div class="relative mt-5 w-full">
        <PhoneInput


          country={"us"} // Default country
          value={formData.mobile}
          onChange={handlePhoneChange}
          inputClass="w-full" // Additional styling if needed
        />
        <label
          for="mobile"
          class="absolute top-1 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
        >
          {" "}
          Mobile
        </label>
        {phoneError && (
          // <p className="text-red-500 text-sm mt-1">{phoneError}</p>
          <p className="text-red-300 font-normal text-xs pt-1">
            {phoneError}
          </p>
        )}
      </div>
    </>
  );
};

export default Detail;






