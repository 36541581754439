import React from "react";
import { Link, useLocation } from "react-router-dom";

const Popup_End_Card = ({ websiteId }) => {
  return (
    <div className="">
      <div className="wrapper  w-[25vw] h-[52vh] border rounded-xl">
        <div className="top-section flex justify-center items-center mx-2 my-2">
          <div className="content  font-semibold text-xl text-white ">
            <h3> 🎉 Congratulations !!</h3>
          </div>

          <div className="cross-icon"></div>
        </div>
        <hr />

        <div className="mid-section rounded-xl bg-[#F1F1F1] mx-2 my-2">
          <div className="count-info py-2">
            <div className="inner-count-info">
              <div className="content text-sm mx-4 mt-1 flex flex-col gap-4">
                <p>
                  You’ve successfully created your first Foot Print and
                  scheduled it as well.
                </p>
                <p>
                  Now when the system is not able to run your foot print, i.e.
                  in the case when something goes wrong with your system, we’ll
                  notify you.
                </p>
                <p>
                  Now go ahead and create more foot prints from your dashboard.
                </p>
              </div>
            </div>
          </div>

          <div className="bottom-section  mx-2 py-2 ">
            <Link to="/footprint-status-list" state={{ websiteId }}>
              <button className="button w-full rounded-lg p-2 bg-button-bg text-xs text-white">
                Go to Dashboard
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup_End_Card;
