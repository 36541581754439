import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../config";
import { VideoLogs } from "./VideoLogs";
const FailedVideo = () => {
  const [video, setVideo] = useState();
  const { videoId } = useParams();
  const { footprintId } = useParams();
  const { websiteId } = useParams();
  // const { videoUrl, logUrl, run, websiteId, footprintId, videoId }
  // console.log("footprintId", footprintId, "websiteId", websiteId);
  console.log("VideoId", videoId);
  const fetchVideo = async () => {
    try {
      const response = await axios.get(`${baseUrl}/videos/${videoId}`);

      console.log("Get request successful for video!", response.data);
      setVideo(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  useEffect(() => {
    if (videoId) {
      fetchVideo();
    }
  }, [videoId]);

  return (
    <>
      <VideoLogs
        videoUrl={video?.url}
        logUrl={video?.logUrl}
        websiteId={websiteId}
        footprintId={footprintId}
        videoId={videoId}
      />
    </>

    // <div className="flex flex-col items-center space-y-8 mx-auto">
    //   <ReactPlayer
    //     url={video && video.url}
    //     width="75%"
    //     height="50%"
    //     controls
    //     playing
    //   />

    //   <div className="ml-2 flex items-center justify-center gap-2 my-2 w-full">
    //     <Link to="/footprint-list" state={{ footprintId }} className="w-2/5">
    //       <button className="bg-button-bg text-white rounded-lg p-2 w-full">
    //         View Info
    //       </button>
    //     </Link>
    //   </div>
    // </div>
  );
};

export default FailedVideo;
