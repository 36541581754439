import React from 'react'
import Intercom from "@intercom/messenger-js-sdk";
import { Link } from 'react-router-dom';
const ResetExpireLink = () => {
    window.intercomSettings = {
        app_id: "x5w0eskb",
        custom_launcher_selector: "#help",
    };
    return (
        <div className="bg-[#252525] flex items-center justify-center min-h-screen ">
            <div class="">
                <div class=" w-104 flex-col space-y-0 rounded-lg border py-5 px-5 mt-2  mx-auto bg-[#FFFFFF]">
                    <div class="">
                        <div className="flex justify-center items-center">
                            {/* <img src={testtube} alt="testtube logo" className="h-[42px]" /> */}
                            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M45.8406 0.585786C45.4655 0.210714 44.9568 0 44.4264 0H19.5736C19.0432 0 18.5345 0.210714 18.1594 0.585786L0.585786 18.1594C0.210714 18.5345 0 19.0432 0 19.5736V44.4264C0 44.9568 0.210714 45.4655 0.585786 45.8406L18.1594 63.4142C18.5345 63.7893 19.0432 64 19.5736 64H44.4264C44.9568 64 45.4655 63.7893 45.8406 63.4142L63.4142 45.8406C63.7893 45.4655 64 44.9568 64 44.4264V19.5736C64 19.0432 63.7893 18.5345 63.4142 18.1594L45.8406 0.585786ZM21.4142 18.5858C20.6332 17.8047 19.3668 17.8047 18.5858 18.5858C17.8047 19.3668 17.8047 20.6332 18.5858 21.4142L29.1716 32L18.5858 42.5858C17.8047 43.3668 17.8047 44.6332 18.5858 45.4142C19.3668 46.1953 20.6332 46.1953 21.4142 45.4142L32 34.8284L42.5858 45.4142C43.3668 46.1953 44.6332 46.1953 45.4142 45.4142C46.1953 44.6332 46.1953 43.3668 45.4142 42.5858L34.8284 32L45.4142 21.4142C46.1953 20.6332 46.1953 19.3668 45.4142 18.5858C44.6332 17.8047 43.3668 17.8047 42.5858 18.5858L32 29.1716L21.4142 18.5858Z" fill="#F7716E" />
                            </svg>
                        </div>
                        <div className="pt-[24px] flex justify-center items-center">
                            <p class="text-[#F5413D] font-semibold text-2xl ">
                            Link Expired
                            </p>
                        </div>

                        <p class="text-color-gray-light font-normal	text-base pt-[8px]	">
                        The password reset link is valid only for 24 hours.
                        </p>

                    </div>



                    <div className="pt-[16px]">
                        <Link to="/reset-password">
                        <button
                            className="rounded-lg h-[40px]  bg-button-bg py-2 border border-[#414141]  w-full font-bold text-white hover:bg-button-hover "
                            
                        >
                            Request again
                        </button>
                        </Link>
                       
                    </div>

                    <div class="flex flex-row space-x-2 pt-[24px]">
                        <a id="help">
                            <p
                                class="font-normal text-xs text-color-gray-light cursor-pointer"
                                onClick={() => Intercom("showNewMessage")}
                            >
                                Help
                            </p>
                        </a>
                        <a href="https://testtube.io/privacy-policy/" target="_blank">
                            <p class="font-normal text-xs text-color-gray-light">Privacy</p>
                        </a>
                        <a href="https://testtube.io/terms-of-use/" target="_blank">
                            <p class="font-normal text-xs text-color-gray-light">Terms</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetExpireLink