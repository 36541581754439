import React from "react";

const Role = ({ roleToggleStates, setRoleToggleStates }) => {
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   console.log("e", event.target.name);
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleChange = (event, id) => {
    setRoleToggleStates((prevState) => ({
      ...prevState,
      [id]: event.target.checked,
    }));
  };
  return (
    <div className="flex justify-around space-x-6">
      <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-7 text-color-gray-light sm:grid-cols-2 sm:gap-6">
        {[
          { label: "CXO", id: "CXO" },
          { label: "Product", id: "Product" },
          { label: "Quality", id: "Quality" },
          { label: "Engineering", id: "Engineering" },
          { label: "Others", id: "Others_role" },
        ].map((item) => (
          <li
            key={item.id}
            className="flex items-center justify-between  w-[300px] bg-[#f2f2f2]   rounded-lg"
          >
            <span className="text-xl tracking-wide font-semibold text-color-gray-light py-[10px] px-[16px]">
              {item.label}
            </span>
            <div className="px-[16px]">
              <input
                type="checkbox"
                className="peer sr-only opacity-0"
                id={item.id}
                value={item.label}
                checked={roleToggleStates[item.id]}
                onChange={(e) => handleChange(e, item.id)}
              />
              <label
                htmlFor={item.id}
                className="relative flex h-6 w-11 cursor-pointer items-center rounded-full bg-[#d5d5d5] px-0.5 outline-gray-400 transition-colors before:h-5 before:w-5 before:rounded-full before:bg-white before:shadow before:transition-transform before:duration-300 peer-checked:bg-[#2AB930] peer-checked:before:translate-x-full peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-gray-400 peer-checked:peer-focus-visible:outline-green-500"
              >
                <span className="sr-only">Enable</span>
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Role;
