

import React from "react";
import Table from "../Table/Table";
import { useNavigate } from "react-router-dom";

const Footprint_Status_Card = ({ footprintId, websiteId }) => {

  return (
    <div className="container">
      <div className="wrapper w-[50vw] h-auto min-h-[58vh] border rounded-xl flex flex-col bg-white">
  

        <div className="flex-grow">
          <Table footprintId={footprintId} websiteId={websiteId} />
        </div>

   
      </div>
    </div>
  );
};

export default Footprint_Status_Card;
