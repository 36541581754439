import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  FaCircle,
  FaRegCircle,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../config";
import Intercom from "@intercom/messenger-js-sdk";
const Footprint_Guide_Card = ({ websiteId, footprintId }) => {
  const navigate = useNavigate();

  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };

  const [isFilled, setIsFilled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleIcon = () => {
    setIsFilled(!isFilled);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [formData, setFormData] = useState({
    interval: "",
    noOfTimes: "",
    footprintId: "",
  });

  useEffect(() => {
    setFormData({
      interval: "",
      noOfTimes: "",
      footprintId: footprintId,
    });
  }, [footprintId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  console.log("formData", formData);

  const handleSchedule = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("Token");

    if (formData.footprintId && formData.interval && formData.noOfTimes) {
      if (token) {
        try {
          const response = await axios.post(`${baseUrl}/schedule`, formData, {
            headers: { authorization: `Bearer ${token}` },
          });
          console.log("response", response.data);
          navigate("/successFootprintPopup", {
            state: { websiteId: websiteId },
          });
        } catch (error) {
          console.log("error", error);
        }
      } else {
        navigate("/signIn");
      }
    } else {
      alert("please enter all fields");
    }
  };

  return (
    <>
      <div className="container ">
        <div className="wrapper  w-[50vw] h-[60vh] border rounded-xl">
          <div className="top-section flex justify-between items-center mx-2 my-2">
            <div className="content text-color-gray-light font-semibold text-xl ">
              <h3 className="text-lg">Setup Guide</h3>
              <p className="text-xs">
                We’ll hand hold you through the process of adding your website
                and then setting up your test.
              </p>
            </div>
          </div>

          <div className="count-info">
            <div className="inner-count-info">
              <div className="content text-gray-100 mx-4 ">
                <button className="button bg-white border rounded-md text-xs text-black px-1 ">
                  2/3 completed
                </button>
              </div>
            </div>
          </div>

          <div className="confirmation-box my-4 flex flex-col gap-2">
            <div className="flex items-center justify-between ">
              <div className="flex items-center mx-2 m ">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 4.96967C11.7374 4.67678 11.2626 4.67678 10.9697 4.96967C10.9626 4.97674 10.9559 4.98424 10.9498 4.9921L7.4774 9.41674L5.38388 7.32322C5.09098 7.03033 4.61611 7.03033 4.32322 7.32322C4.03032 7.61612 4.03032 8.09099 4.32322 8.38388L6.96966 11.0303C7.26256 11.3232 7.73743 11.3232 8.03032 11.0303C8.03685 11.0238 8.043 11.0169 8.04876 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967Z"
                    fill="#52D858"
                  />
                </svg>

                <div className="text-color-gray-light text-[14px] font-semibold ml-2">
                  Domain Verification
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center mx-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 4.96967C11.7374 4.67678 11.2626 4.67678 10.9697 4.96967C10.9626 4.97674 10.9559 4.98424 10.9498 4.9921L7.4774 9.41674L5.38388 7.32322C5.09098 7.03033 4.61611 7.03033 4.32322 7.32322C4.03032 7.61612 4.03032 8.09099 4.32322 8.38388L6.96966 11.0303C7.26256 11.3232 7.73743 11.3232 8.03032 11.0303C8.03685 11.0238 8.043 11.0169 8.04876 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967Z"
                    fill="#52D858"
                  />
                </svg>

                <div className="text-color-gray-light text-[14px] font-semibold ml-2">
                  Create your 1st test case
                </div>
              </div>
            </div>
          </div>

          <div className="mid-section rounded-xl bg-[#F1F1F1] mx-2 ">
            <div className="p-1">
              <div className="flex items-center justify-between ">
                <div className="flex items-center ml-4">
                  <button onClick={toggleIcon} className="focus:outline-none">
                    {isFilled ? (
                      <FaCircle className="text-[#C52CEE]" />
                    ) : (
                      <FaRegCircle className="text-gray-500" />
                    )}
                  </button>
                  <div className="text-color-gray-light font-semibold text-md ml-2">
                    Schedule your Test
                  </div>
                </div>
              </div>
              <p className="text-sm my-4 mx-2">
                Schedule foot prints to continue to run at a particular
                frequency for a set duration.
              </p>
              <div className="flex space-x-4 mx-2">
                <div className="relative w-full text-sm">
                  <select
                    id="floatingSelect1"
                    className="block w-full px-2.5 pb-2.5 pt-4 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500 peer"
                    name="noOfTimes"
                    onChange={handleChange}
                  >
                    <option> Select the Interval</option>
                    <option value="1">once every hours</option>
                    <option value="2">twice every hours</option>
                  </select>
                  <label
                    htmlFor="floatingSelect1"
                    className="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5"
                  >
                    Interval
                  </label>
                </div>
                <div className="relative w-full text-sm">
                  <select
                    id="floatingSelect2"
                    className="block w-full px-2.5 pb-2.5 pt-4 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500 peer"
                    name="interval"
                    onChange={handleChange}
                  >
                    {/* <option value="" disabled></option> */}
                    <option>Select the Duration</option>
                    <option value="2 minutes">For the next 2 days</option>

                    <option value="4 minutes">For the next 4 days</option>
                  </select>
                  <label
                    htmlFor="floatingSelect2"
                    className="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5"
                  >
                    Duration
                  </label>
                </div>
              </div>
            </div>

            <div className="p-1">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="ml-2 flex items-center gap-2 my-2">
                    <button
                      className="button bg-button-bg text-white text-xs rounded-lg p-2"
                      onClick={handleSchedule}
                    >
                      Schedule Test
                    </button>
                    <a id="help">
                      <span
                        className="text-xs cursor-pointer"
                        onClick={() => Intercom("showNewMessage")}
                      >
                        Connect with us for Help
                      </span>
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footprint_Guide_Card;
