// import React, { useEffect, useState } from "react";
// import testtube from "../Images/TestTube.png";
// import { FeedbackFish } from "@feedback-fish/react";
// import axios from "axios";
// import Intercom from "@intercom/messenger-js-sdk";
// import { baseUrl } from "../../config";
// import { Link, useNavigate } from "react-router-dom";
// const Navbar = () => {
//   const userId = localStorage.getItem("userId");
//   const navigate = useNavigate();
//   const [user, setUser] = useState();
//   const [searchQuery, setSearchQuery] = useState("");
//   const [searchResults, setSearchResults] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [hideResult, setHideResult] = useState(false);
//   Intercom({
//     app_id: "x5w0eskb",
//     user_id: user?._id,
//     name: user?.username,
//     email: user?.email,
//     created_at: user?.createdAt,
//   });

//   const fetchUserById = async () => {
//     try {
//       const response = await axios.get(`${baseUrl}/user/${userId}`);
//       console.log("response from userDetails", response.data);
//       setUser(response.data);
//     } catch (error) {
//       console.log("Error in fetching data", error.response);
//     }
//   };

//   const performSearch = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get(
//         `${baseUrl}/search?q=${searchQuery}&userId=${userId}`
//       );
//       console.log("searchResult", response.data);
//       setSearchResults(response.data);
//     } catch (error) {
//       console.error("Error fetching search results:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     const delayDebounceFn = setTimeout(() => {
//       if (searchQuery) {
//         performSearch();
//       } else {
//         setSearchResults([]);
//       }
//     }, 300);

//     return () => clearTimeout(delayDebounceFn);
//   }, [searchQuery]);
//   useEffect(() => {
//     fetchUserById();
//   }, []);

//   const handleClickResult = (result) => {
//     const data = {
//       footprintId: result?._id,
//       websiteId: result?.website,
//     };
//     navigate(`/footprint-list`, { state: data });
//     setHideResult(true);
//     setSearchQuery(result.name);
//   };

//   const handleSearchQuery = (e) => {
//     setHideResult(!hideResult);
//     setSearchQuery(e.target.value);
//   };

//   const TagsDisplay = ({ tags }) => {
//     // Join the tags array into a single string with spaces
//     const joinedTags = tags.join(' ');

//     return (
//       <p className="text-xs text-gray-600 truncate">
//         <span className="mr-2 text-gray-300">tags</span>
//         {joinedTags}
//       </p>
//     );
//   };

//   return (
//     <>
//       <div className="">
//         <div className="wrapper">
//           <div className="navbar bg-black position-fixed">
//             <div className="inner-wrapper flex justify-between items-center p-[12px]">
//               <div className="logo flex-1">
//                 <Link to="/dashboard">
//                   <img
//                     src={testtube}
//                     alt="testtube logo"
//                     className="h-[40px]"
//                   />
//                 </Link>
//               </div>

//               <div className="relative  mx-20" style={{ width: "60%" }}>
//                 <div className="relative flex items-center">
//                   <svg
//                     width="20"
//                     height="20"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                     className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       clipRule="evenodd"
//                       d="M16.8688 15.4573C19.3126 12.3188 19.0918 7.77813 16.2067 4.89295C13.0825 1.76876 8.01714 1.76876 4.89295 4.89295C1.76876 8.01714 1.76876 13.0825 4.89295 16.2067C7.77813 19.0918 12.3188 19.3126 15.4573 16.8688C15.4708 16.8841 15.4849 16.8991 15.4996 16.9138L19.7422 21.1564C20.1327 21.5469 20.7659 21.5469 21.1564 21.1564C21.5469 20.7659 21.5469 20.1327 21.1564 19.7422L16.9138 15.4996C16.8991 15.4849 16.8841 15.4708 16.8688 15.4573ZM14.7924 6.30716C17.1356 8.65031 17.1356 12.4493 14.7924 14.7924C12.4493 17.1356 8.65031 17.1356 6.30716 14.7924C3.96402 12.4493 3.96402 8.65031 6.30716 6.30716C8.65031 3.96402 12.4493 3.96402 14.7924 6.30716Z"
//                       fill="#E0E0E0"
//                     />
//                   </svg>
//                   <input
//                     type="text"
//                     placeholder="Search to find your websites, tests, results and more"
//                     value={searchQuery}
//                     onChange={(e) => handleSearchQuery(e)}
//                     className="w-full pl-10 pr-4 py-1 text-sm tracking-wider rounded-lg border placeholder-[#E0E0E0] border-[#616161] bg-gray-800 text-white focus:outline-none focus:border-[#616161]"
//                   />
//                 </div>

//                 {!hideResult &&
//                   (isLoading ||
//                     searchResults?.length > 0 ||
//                     (searchQuery && !isLoading)) && (
//                     // <div
//                     //   className="absolute cursor-pointer z-50 w-full mt-1 bg-white shadow-lg rounded-lg"
//                     //   style={{ maxHeight: "300px" }}
//                     // >
//                     <div
//                       className="absolute cursor-pointer z-50 w-full mt-1 bg-white shadow-lg rounded-lg overflow-y-auto   custom-scrollbar"
//                       style={{ maxHeight: "300px" }}
//                     >
//                       <div className="py-2 px-4 h-full ">
//                         {isLoading && (
//                           <p className="text-gray-600 text-sm py-1">
//                             Loading...
//                           </p>
//                         )}
//                         {!isLoading &&
//                           searchResults &&
//                           searchResults?.length > 0 && (
//                             <ul className="space-y-0.5">
//                               {searchResults.map((result, index) => (
//                                 <li
//                                   key={index}
//                                   onClick={() => handleClickResult(result)}
//                                   className="border-b border-gray-200 last:border-b-0 py-1 hover:bg-gray-100  transition-colors duration-150"
//                                 >
//                                   <div className="flex justify-between">
//                                     <p className="text-xs font-semibold text-gray-800 truncate">
//                                       {result.name}
//                                     </p>
//                                     <p className="text-xs font-semibold text-gray-800 truncate">
//                                       <span className="mr-2 text-gray-300">
//                                         status
//                                       </span>{" "}
//                                       {result.status}
//                                     </p>
//                                   </div>
//                                   <div className="flex justify-between">
//                                     <p className="text-xs text-gray-600 truncate">
//                                       {result.footprintUrl}
//                                     </p>
//                                     <p className="text-xs text-gray-600 truncate">
//                                       <span className="mr-2 text-gray-300">
//                                         tags
//                                       </span>{" "}
//                                       {/* {result.tags} */}

//                                     </p>
//                                   </div>
//                                 </li>
//                               ))}
//                             </ul>
//                           )}
//                         {!isLoading &&
//                           searchQuery &&
//                           searchResults?.length === 0 && (
//                             <p className="text-gray-600 text-sm py-1">
//                               No data found
//                             </p>
//                           )}
//                       </div>
//                     </div>
//                   )}
//               </div>

//               <div className="flex items-center space-x-3">
//                 <FeedbackFish projectId="b5c3da700bc1a7" userId={user?.email}>
//                   <button className="button bg-button-bg text-white text-xs rounded-lg p-1.5 flex items-center">
//                     <span>Share Feedback</span>
//                   </button>
//                 </FeedbackFish>
//                 <svg
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     d="M12 24C13.6569 24 15 22.6569 15 21H9C9 22.6569 10.3431 24 12 24Z"
//                     fill="white"
//                   />
//                   <path
//                     fill-rule="evenodd"
//                     clip-rule="evenodd"
//                     d="M12 2.87737L10.8042 3.11888C8.06329 3.67243 6.00003 6.09694 6.00003 9C6.00003 9.94174 5.79849 12.2959 5.31173 14.6132C5.0701 15.7636 4.74681 16.9614 4.31714 18H19.6829C19.2532 16.9614 18.9299 15.7636 18.6883 14.6132C18.2016 12.2959 18 9.94173 18 9C18 6.09693 15.9368 3.6724 13.1958 3.11887L12 2.87737ZM21.329 18C21.6638 18.6711 22.0522 19.2015 22.5 19.5H1.5C1.94779 19.2015 2.33617 18.6711 2.67105 18C4.01883 15.2991 4.50003 10.3187 4.50003 9C4.50003 5.36901 7.0803 2.34067 10.5073 1.64856C10.5025 1.59969 10.5 1.55013 10.5 1.5C10.5 0.671573 11.1716 0 12 0C12.8284 0 13.5 0.671573 13.5 1.5C13.5 1.55013 13.4975 1.59968 13.4927 1.64855C16.9197 2.34063 19.5 5.36899 19.5 9C19.5 10.3187 19.9812 15.2991 21.329 18Z"
//                     fill="white"
//                   />
//                 </svg>
//                 <span className="text-white ml-2">
//                   {user?.username.split(/(?=[A-Z])/)[0]}
//                 </span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Navbar;

import React, { useEffect, useRef, useState } from "react";
// import testtube from "../Images/TestTube.png";
// import testtube from "../components/Images/Testtube logo.png";
import testtube from "../Images/Testtube logo.png";
import { FeedbackFish } from "@feedback-fish/react";
import axios from "axios";
import Intercom from "@intercom/messenger-js-sdk";
import { baseUrl } from "../../config";
import { Link, useNavigate } from "react-router-dom";
const Navbar = () => {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hideResult, setHideResult] = useState(false);
  const searchAreaRef = useRef(null);
  Intercom({
    app_id: "x5w0eskb",
    user_id: user?._id,
    name: user?.username,
    email: user?.email,
    created_at: user?.createdAt,
  });

  const fetchUserById = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/${userId}`);
      console.log("response from userDetails", response.data);
      setUser(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const performSearch = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/search?q=${searchQuery}&userId=${userId}`
      );
      console.log("searchResult", response.data);
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        performSearch();
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);
  useEffect(() => {
    fetchUserById();
  }, []);

  const handleClickResult = (result) => {
    const data = {
      footprintId: result?._id,
      websiteId: result?.website,
    };
    navigate(`/footprint-list`, { state: data });
    setHideResult(true);
    setSearchQuery(result.name);
  };

  const handleSearchQuery = (e) => {
    setHideResult(!hideResult);
    setSearchQuery(e.target.value);
  };

  // const TagsDisplay = ({ tags }) => {
  //   // Join the tags array into a single string with spaces
  //   const joinedTags = tags.join("  ");

  //   return (
  //     <p className="text-xs text-gray-600 truncate">
  //       {joinedTags}
  //       <span className="ml-2 text-gray-300">tags</span>
  //     </p>
  //   );
  // };

  const TagsDisplay = ({ tags }) => {
    // Join the tags array into a single string with spaces
    const joinedTags = tags.join(" , ");

    return (
      <p className="text-xs text-gray-600 truncate">
        {joinedTags}
        <span className="ml-2 text-gray-300">tags</span>
      </p>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchAreaRef.current &&
        !searchAreaRef.current.contains(event.target)
      ) {
        setHideResult(true); // Hide results if clicked outside search area
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchAreaRef]);

  return (
    <>
      <div className="bg-button-bg-nav sticky top-0 z-50">
        <div className="wrapper">
          <div className="navbar bg-button-bg-nav  ">
            <div className="inner-wrapper flex justify-between items-center p-[12px]">
              <div className="logo flex-1">
                <Link to="/dashboard">
                  {/* <img
                    src={testtube}
                    alt="testtube logo"
                    className="h-[42px]"
                  /> */}
                  <div className="logo flex items-center  gap-2">
                    <svg width="33" height="33" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.54286 3.77143H60.3429V61.2857H7.54286V3.77143Z" fill="white" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 11.3143C0 5.06558 5.06558 0 11.3143 0H54.6857C60.9344 0 66 5.06558 66 11.3143V54.6857C66 60.9344 60.9344 66 54.6857 66H11.3143C5.06558 66 0 60.9344 0 54.6857V11.3143ZM14.1429 15.1777C14.1429 13.1486 14.6807 11.6831 15.7563 10.7813C16.8768 9.87949 18.3782 9.42857 20.2605 9.42857H45.7396C47.7564 9.42857 49.2578 9.92458 50.2438 10.9166C51.3195 11.8635 51.8573 13.2839 51.8573 15.1777C51.8573 17.1167 51.3195 18.5596 50.2438 19.5065C49.2578 20.4985 47.7564 20.9945 45.7396 20.9945H39.8909C37.7844 21.0396 35.9917 21.2876 34.5127 21.7385C33.0337 22.1894 31.7788 22.7982 30.748 23.5647C29.762 24.3313 28.9776 25.2331 28.395 26.2702C27.8124 27.3073 27.3866 28.412 27.1177 29.5844V20.9945H20.2605C18.2437 20.9945 16.7423 20.4985 15.7563 19.5065C14.6807 18.5596 14.1429 17.1167 14.1429 15.1777ZM38.8118 49.3343C38.8118 50.3263 38.6998 51.2732 38.4757 52.175C38.2964 53.0318 37.9827 53.7983 37.5345 54.4747C37.0863 55.106 36.4813 55.6245 35.7194 56.0303C35.0023 56.3911 34.1059 56.5714 33.0303 56.5714C31.9547 56.5714 31.0359 56.3911 30.274 56.0303C29.5121 55.6245 28.8846 55.106 28.3916 54.4747C27.9434 53.7983 27.6073 53.0318 27.3832 52.175C27.2039 51.2732 27.1143 50.3263 27.1143 49.3343V35.401C27.1143 31.7486 28.1451 28.9304 30.2067 26.9464C32.2684 24.9624 35.1367 23.9027 38.8118 23.7674V49.3343Z" fill="#5049AC" />
                    </svg>
                    <span className="font-bold text-white ">TestTube</span>
                  </div>

                </Link>
              </div>

              <div
                className="relative  mx-20"
                style={{ width: "60%" }}
                ref={searchAreaRef}
              >
                <div className="relative flex items-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.8688 15.4573C19.3126 12.3188 19.0918 7.77813 16.2067 4.89295C13.0825 1.76876 8.01714 1.76876 4.89295 4.89295C1.76876 8.01714 1.76876 13.0825 4.89295 16.2067C7.77813 19.0918 12.3188 19.3126 15.4573 16.8688C15.4708 16.8841 15.4849 16.8991 15.4996 16.9138L19.7422 21.1564C20.1327 21.5469 20.7659 21.5469 21.1564 21.1564C21.5469 20.7659 21.5469 20.1327 21.1564 19.7422L16.9138 15.4996C16.8991 15.4849 16.8841 15.4708 16.8688 15.4573ZM14.7924 6.30716C17.1356 8.65031 17.1356 12.4493 14.7924 14.7924C12.4493 17.1356 8.65031 17.1356 6.30716 14.7924C3.96402 12.4493 3.96402 8.65031 6.30716 6.30716C8.65031 3.96402 12.4493 3.96402 14.7924 6.30716Z"
                      fill="#E0E0E0"
                    />
                  </svg>
                  {/* <input
                    type="text"
                    placeholder="Search to find your websites, tests, results and more"
                    value={searchQuery}
                    onChange={(e) => handleSearchQuery(e)}

                    style={{background:"linear-gradient(0deg, #616161, #616161),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));"}}

                    className="w-full pl-10 pr-4 py-1 text-white bg-[#616161] text-sm tracking-wider border border-[#616161] placeholder-white focus:outline-none bg-opacity-90 rounded-lg"
                  /> */}

                  <input
                    type="text"
                    placeholder="Search to find your websites, tests, results and more"
                    value={searchQuery}
                    onChange={(e) => handleSearchQuery(e)}
                    // style={{
                //       background: `linear-gradient(0deg, #616161, #616161),
                //  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                //  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                //  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                //  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
                //  border: "1px solid",
                //  borderImageSource: "linear-gradient(180deg, #535353 0%, #393939 100%)",
                    // }}
                    style={{
                      backgroundColor: 'rgba(30, 30, 30, 0.9)', // dark background with 90% opacity
                      // border: '1px solid',
                      // borderImageSource: 'linear-gradient(180deg, #535353 0%, #393939 100%)',
                    }}
                    className="w-full pl-10 pr-4 py-1 text-white  border border-[#393939] text-sm tracking-wider focus:outline-none   rounded-lg placeholder-white"

                  />





                </div>

                {!hideResult &&
                  (isLoading ||
                    searchResults?.length > 0 ||
                    (searchQuery && !isLoading)) && (
                    // <div
                    //   className="absolute cursor-pointer z-50 w-full mt-1 bg-white shadow-lg rounded-lg"
                    //   style={{ maxHeight: "300px" }}
                    // >
                    <div
                      id=""
                      className="absolute cursor-pointer z-50 w-full mt-1 bg-white shadow-lg rounded-lg overflow-y-auto   custom-scrollbar"
                      style={{ maxHeight: "300px" }}
                    >
                      <div className="py-2 px-4 h-full ">
                        {isLoading && (
                          <p className="text-gray-600 text-sm py-1">
                            Loading...
                          </p>
                        )}
                        {!isLoading &&
                          searchResults &&
                          searchResults?.length > 0 && (
                            <ul className="space-y-0.5">
                              {searchResults.map((result, index) => (
                                <li
                                  key={index}
                                  onClick={() => handleClickResult(result)}
                                  className="border-b border-gray-200 last:border-b-0 py-1 hover:bg-gray-100  transition-colors duration-150"
                                >
                                  <div className="flex justify-between">
                                    <p className="text-xs font-semibold text-gray-800 truncate">
                                      {result.name}
                                    </p>
                                    <p className="text-xs  text-gray-600 truncate">
                                      {result.status}
                                      <span className="ml-2 text-gray-300">
                                        status
                                      </span>{" "}
                                    </p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p className="text-xs text-gray-600 truncate">
                                      {result.footprintUrl}
                                    </p>
                                    <p className="text-xs text-gray-600 truncate">
                                      {/* {result.tags} */}
                                      {/* <TagsDisplay tags={result.tags} /> */}
                                      <TagsDisplay tags={result.tagsArray} />
                                    </p>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        {!isLoading &&
                          searchQuery &&
                          searchResults?.length === 0 && (
                            <p className="text-gray-600 text-sm py-1">
                              No data found
                            </p>
                          )}
                      </div>
                    </div>
                  )}
              </div>

              <div className="flex items-center space-x-3">
                <FeedbackFish projectId="b5c3da700bc1a7" userId={user?.email}>
                  <button className="button bg-button-bg text-white text-xs rounded-lg p-1.5 flex items-center">
                    <span>Share Feedback</span>
                  </button>
                </FeedbackFish>
                {/* <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 24C13.6569 24 15 22.6569 15 21H9C9 22.6569 10.3431 24 12 24Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 2.87737L10.8042 3.11888C8.06329 3.67243 6.00003 6.09694 6.00003 9C6.00003 9.94174 5.79849 12.2959 5.31173 14.6132C5.0701 15.7636 4.74681 16.9614 4.31714 18H19.6829C19.2532 16.9614 18.9299 15.7636 18.6883 14.6132C18.2016 12.2959 18 9.94173 18 9C18 6.09693 15.9368 3.6724 13.1958 3.11887L12 2.87737ZM21.329 18C21.6638 18.6711 22.0522 19.2015 22.5 19.5H1.5C1.94779 19.2015 2.33617 18.6711 2.67105 18C4.01883 15.2991 4.50003 10.3187 4.50003 9C4.50003 5.36901 7.0803 2.34067 10.5073 1.64856C10.5025 1.59969 10.5 1.55013 10.5 1.5C10.5 0.671573 11.1716 0 12 0C12.8284 0 13.5 0.671573 13.5 1.5C13.5 1.55013 13.4975 1.59968 13.4927 1.64855C16.9197 2.34063 19.5 5.36899 19.5 9C19.5 10.3187 19.9812 15.2991 21.329 18Z"
                    fill="white"
                  />
                </svg> */}
                <span className="text-white ml-2">
                  {user?.username.split(/(?=[A-Z])/)[0]}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
