import React, { useEffect, useState } from 'react'
import Guide1 from '../components/Guide/Guide1'
import ExtensionAlert from '../components/Card/ExtensionAlert';

const AddWebsitePage = () => {
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
    useEffect(() => {
        const handleLoad = () => {
            console.log("Page fully loaded");
            const messageListener = (event) => {
                if (event.source !== window) return;
                if (event.data.type === "EXTENSION_INSTALLED") {
                    console.log("Extension is installed:", event.data.data);
                    setIsExtensionInstalled(true);

                }
            };

            // Add event listener for messages
            window.addEventListener("message", messageListener);

            // Send a message to check if the extension is installed
            console.log("Sending CHECK_EXTENSION message");
            window.postMessage({ type: "CHECK_EXTENSION" }, "*");

            // Clean up event listener on component unmount
            return () => {
                window.removeEventListener("message", messageListener);
            };
        };

        // Check if document is already loaded
        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);

            // Clean up the load event listener
            return () => {
                window.removeEventListener('load', handleLoad);
            };
        }
    }, []);
    return (
        // <div className="setup-guide mt-5">
        //         <div className="inner-setup-guide text-color-gray-light">
        //         <Guide1 /> 
        //         </div>
        //       </div>
        <>
            {!isExtensionInstalled && <ExtensionAlert />}

            <div className="container flex justify-center  m-auto h-[50vh] w-[60vw]  ">

                <div
                    className={`space-y-4 mx-4 py-2 flex flex-col gap-2 ${!isExtensionInstalled ? 'non-clickable' : ''
                        }`}
                    style={{
                        pointerEvents: isExtensionInstalled ? 'auto' : 'none',
                        opacity: isExtensionInstalled ? 1 : 0.5, // Optional: visually indicate non-clickability
                    }}
                >
                    <Guide1 />
                </div>

            </div>
        </>

    )
}

export default AddWebsitePage