import React from "react";

const DeleteModal = ({ show, onClose, onDelete }) => {
  if (!show) return null;

  return (
    <div className=" z-50 fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg w-full p-6">
        <h2 className="text-xl font-bold mb-2">Delete Footprint</h2>
        <p className="mb-4">Are you sure you want to delete this footprint?</p>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={onDelete}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
