import React, { useState, useEffect } from "react";
import CustomSelect from "../Input/CustomSelect";
import CustomSelectStatus from "../Input/CustomSelectStatus";
import Footprint_All_Status_Table from "../Table/Footprint_All_Status_Table";
import { Link, useNavigate } from "react-router-dom";
import ExtensionAlert from "./ExtensionAlert";

const Footprint_All_List_Status_Card = ({ websiteId }) => {
  const navigate = useNavigate()
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const handleSelectChange = (newSelection) => {
    setSelectedValues(newSelection);
  };


  const handleStatusChange = (newSelection) => {
    setSelectedStatus(newSelection);
  };

  // const handleCreateTest = () => {
  //   const data = { websiteId: websiteId };
  //   navigate(`/dashboard`, { state: data });
  // };

  useEffect(() => {
    console.log(selectedValues, "nieiie");
  }, [selectedValues]);
  return (
    <div className="container">
      <div className="wrapper w-full h-auto min-h-[58vh] border rounded-xl flex flex-col bg-white">
        {/* <ExtensionAlert /> */}

        <div className="top-section flex justify-between items-center mx-4 mt-4">

          {/* Left Side Content */}
          <div className="content text-black font-bold text-xl">
            <h3 className="text-lg">Test Report</h3>
          </div>

          {/* Right Side Content */}
          <div className="mid-section flex space-x-4">
            <div className="relative w-48 text-sm">
              <CustomSelect
                onChange={handleSelectChange}
                websiteId={websiteId}
              />
            </div>

            {/* Second Custom Select */}
            <div className="relative w-48 text-sm">
              <CustomSelectStatus onChange={handleStatusChange} />
            </div>
            <div className="relative  text-sm">
              {/* <Link to= '/dashboard' state={{  websiteId }}>
                <button className="button bg-button-bg text-white text-xs rounded-md p-2 flex items-center">
                  <span>Create Test</span>
                </button>
              </Link> */}

              <Link to={`/${websiteId}/dashboard`} >
                <button className="button bg-button-bg  hover:bg-button-hover-color text-white text-xs rounded-md p-2 flex items-center">
                  <span>Create Test</span>
                </button>
              </Link>


            </div>
          </div>
        </div>

        <div className="flex-grow">
          <Footprint_All_Status_Table
            websiteId={websiteId}
            selectedTags={selectedValues}
            selectedStatus={selectedStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default Footprint_All_List_Status_Card;
