import React, { useEffect, useState } from "react";
import {
  FaCircle,
  FaRegCircle,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import FloatingInput from "../Input/FloatingInput";
import Footprint_Schedule from "../Footprint/Footprint_Schedule";
import axios from "axios";
import { baseUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import Intercom from "@intercom/messenger-js-sdk";

const Footprint_Save_Card = ({ showSection, footprintId, websiteId, status }) => {
  const [isFilled, setIsFilled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showScheduleModel, setShowScheduleModel] = useState(false);
  const [footPrint, setFootprint] = useState();
  const navigate = useNavigate();

  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };
  const [formData, setFormData] = useState({
    footprintName: "",
    tags: "",
    tagsArray: [],
  });

  const toggleIcon = () => {
    setIsFilled(!isFilled);
  };

  const createSchedule = () => {
    setShowScheduleModel(true);
    showSection(true);
  };

  const handleSaveSchedule = () => {
    const data = { websiteId: websiteId };
    navigate("/footprint-status-list", { state: data });
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const fetchFootprintById = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/footprint/foot/${footprintId}`
      );
      console.log("response from footprint by Id", response.data);
      setFootprint(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  useEffect(() => {
    if (footprintId) {
      fetchFootprintById();
    }
  }, [footprintId]);

  useEffect(() => {
    if (footPrint) {
      let Tags = footPrint?.tags;
      let footprintTags = Tags?.join(", ");
      setFormData({
        footprintName: footPrint?.name || "",
        tags: "",
        tagsArray: footPrint?.tagsArray,
      });
    }
  }, [footprintId, footPrint]);
  return (
    <>
      {!showScheduleModel && (
        <div className="container ">
          {/** h-58vh removed */}
          <div className="wrapper  w-[50vw]  border border-[#E0E0E0] rounded-xl bg-white">
            <div className="top-section flex justify-between items-center mx-4 my-4">
              <div className="content text-color-gray-light font-semibold text-xl ">
                <h3 className="text-lg">New Foot Print</h3>
              </div>
            </div>

            <FloatingInput
              footPrint={footPrint}
              isReadOnly={true}
              isEditable={true}
              formData={formData}
              setFormData={setFormData}
            />

            <div className="count-info">
              <div className="inner-count-info flex gap-2 text-xs  mx-4">
                <div className="content text-gray-100  mt-1">
                  <button className="button bg-white border rounded-md text-black px-1.5 py-0.5 mt-2">
                    {footPrint?.capturedSelectors?.length} steps
                  </button>
                </div>
                {/* <div className="content text-gray-100  mt-1">
                  <button className="button bg-white border rounded-md text-black px-1 mt-2">
                    34 seconds
                  </button>
                </div> */}
              </div>
            </div>

            <div className="p-1 mb-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="ml-2 flex items-center gap-3 my-2 justify-center">
                    <button
                      id="button_schedule_now"
                      // className="button bg-button-bg text-white text-xs rounded-lg px-2 py-1 flex items-center space-x-2 mt-2"
                      className="button bg-button-bg hover:bg-button-hover-color text-xs text-white rounded-lg px-2 py-1.5 flex items-center space-x-2  border border-[#252525]"
                      onClick={createSchedule}
                    >
                      <span>Schedule Now</span>
                      <svg
                        className=""
                        width="12"
                        height="12"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 5H10V10H5V12H12V5Z" fill="white" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10Z"
                          fill="white"
                        />
                      </svg>
                    </button>

                    <button
                      id="button_save_sch"
                      className="button  text-xs rounded-lg px-2 py-1 flex items-center space-x-2  border border-[#252525]"
                      onClick={handleSaveSchedule}
                    >
                      <span>Save & Schedule Later</span>
                      <svg
                        className="mb-1"
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.6401 7.02539H12.0186V5.02539H19.0186V12.0254H17.0186V8.4754L12.1046 13.3893L10.6904 11.9751L15.6401 7.02539Z"
                          fill="black"
                        />
                        <path
                          d="M10.9824 6.97485H4.98242V18.9749H16.9824V12.9749H14.9824V16.9749H6.98242V8.97485H10.9824V6.97485Z"
                          fill="black"
                        />
                      </svg>
                    </button>

                    <a id="help" className="mt-[-4px]">
                      <span
                        className="text-xs  text-black cursor-pointer"
                        onClick={() => Intercom("showNewMessage")}
                      >
                        Connect with us for Help
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showScheduleModel && (
        <Footprint_Schedule
          status={status}
          footPrint={footPrint}
          websiteId={websiteId}
          isReadOnly={true}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </>
  );
};

export default Footprint_Save_Card;
