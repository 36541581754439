import React from "react";

import women from "../Images/TestTube.png";
import { useNavigate } from "react-router-dom";
const Footprint_Landing_Card = () => {
  const navigate = useNavigate();
  const createFootprint = () => {
    navigate("/footprint");
  };
  return (
    <div className="container">
      <div className="wrapper w-full h-auto min-h-[58vh] border rounded-xl flex flex-col bg-white">
        <div className="inner-wrapper">
          <div className="flex flex-col gap-2 items-center justify-center p-4 my-8 ">
            <div className="image w-[50px] h-full ">
              <img src={women} alt="" className="w-full h-full object-cover" />
            </div>
            <div className="content text-center">
              <h2 className="text-color-gray-light font-bold">
                You do not have any test yet.
              </h2>
              <p className="text-xs my-2">
                Create tests for features that are essential for your business.
                We’ll <br /> keep testing them and when they fail, we’ll notify
                you.
              </p>
            </div>
            <div className="flex items-center justify-center ">
              <button
                className="button bg-button-bg hover:bg-button-hover-color text-white text-xs rounded-lg p-2 flex items-center space-x-2"
                onClick={createFootprint}
              >
                <span> Create Test</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footprint_Landing_Card;
