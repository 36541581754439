


import axios from "axios";
import React, { useState, useEffect } from "react";
import { baseUrl } from "../../config";

const Popup_Card = ({ onClose, websiteId, footprintUrl, formData, urlToSend }) => {
  const [websiteUrl, setWebsiteUrl] = useState();
  const [checked, setChecked] = useState(false);
  const websiteDataId=formData?.websiteId ||websiteId;
  console.log("data for popUp", websiteId, footprintUrl);
  const handleChange = async (event) => {
    const isChecked = event.target.checked;
    console.log("mai check hu",isChecked)
    setChecked(isChecked);  // Update checkbox state

    // Call the function to update showPopUp in the backend
    await offPopUp(isChecked);
  };

  const offPopUp = async (isChecked) => {
    try {
      const response = await axios.put(`${baseUrl}/websites/closePopup/${websiteDataId}`, {
        showPopUp: isChecked
      });
      console.log(response.data, "Checkbox updated");
    } catch (error) {
      console.error("Error updating popup status:", error);
    }
  };


  const fetchWebsiteByUserId = async () => {
    const userId = localStorage.getItem("userId");

    const storedWebsiteId = localStorage.getItem("latestWebsiteId");
    try {
      if (storedWebsiteId) {
        const response = await axios.get(`${baseUrl}/websites/${storedWebsiteId}`);
        console.log("response from website", response.data.url);
        setWebsiteUrl(response.data?.url);
      }
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };
  useEffect(() => {
    fetchWebsiteByUserId();
  }, []);

  const createFootprint = async () => {


    offPopUp();
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("Token");
    localStorage.removeItem('latestWebsiteId');


    console.log("issue ye hai", userId, token, websiteUrl);

    // window.postMessage(
    //   {
    //     type: "START_TRACKING",
    //     url: footprintUrl,
    //     userId: userId,
    //     websiteId: websiteId,
    //     footprintName: "Test Tube",
    //     websiteUrl: websiteUrl,
    //     token: token,
    //   },
    //   "*"
    // );


    // Send the message with default values or values from formData and urlToSend if they exist
    window.postMessage(
      {
        type: "START_TRACKING",
        url: (formData && formData.footprintUrl) || footprintUrl,
        userId: userId,
        websiteId: (formData && formData.websiteId) || websiteId,
        footprintName: (formData && formData.footprintName) || "Test Tube",
        tags: formData?.tags ,  // Default to an empty array if tags are not provided
        websiteUrl: urlToSend || websiteUrl,
        tagsArray: formData?.tagsArray ,
        token: token,
      },
      "*"
    );



    console.log("Start tracking message sent to extension");
  };

  return (
    <div className="container ">
      {/** h-auto min-h-[50vh] removed */}
      <div className="wrapper  bg-white mx-auto  w-[25vw]  border rounded-xl">
        <div className="top-section flex justify-between items-center mx-2 my-2">
          <div className="content  font-semibold text-xl ">
            <h3> Add Test </h3>
          </div>

          <div className="cross-icon">
            <svg
              onClick={onClose}
              className="cursor-pointer"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="6" fill="white" />
              <g clip-path="url(#clip0_7598_255645)">
                <path
                  d="M14.2257 12.811C13.8351 12.4204 13.202 12.4204 12.8114 12.811C12.4209 13.2015 12.4209 13.8346 12.8114 14.2252L18.5862 19.9999L12.8115 25.7746C12.421 26.1651 12.421 26.7983 12.8115 27.1888C13.202 27.5793 13.8352 27.5793 14.2257 27.1888L20.0004 21.4141L25.7751 27.1888C26.1656 27.5793 26.7988 27.5793 27.1893 27.1888C27.5798 26.7983 27.5798 26.1651 27.1893 25.7746L21.4146 19.9999L27.1893 14.2252C27.5799 13.8346 27.5799 13.2015 27.1893 12.811C26.7988 12.4204 26.1657 12.4204 25.7751 12.811L20.0004 18.5857L14.2257 12.811Z"
                  fill="#414141"
                />
              </g>
              <defs>
                <clipPath id="clip0_7598_255645">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(8 8)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <hr />

        <div className="mid-section rounded-xl bg-[#F1F1F1] mx-2 my-1">
          <div className="count-info py-2">
            <div className="inner-count-info">
              <div className="content text-sm mx-4 mt-1">
                <p>
                  You will now be taken to a new tab to create a foot print.
                  These will be the steps the system will continue to run.
                </p>
                <div className="flex items-center my-4">
                  <input type="checkbox" id="doNotShow" className="mr-2" onChange={handleChange} />
                  <label
                    htmlFor="doNotShow"
                    className="text-color-gray-light text-sm"
                  >
                    Do not show this again
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-section mt-12 mx-2 py-4 ">
            <button
              id="button_start_first_test"
              className="button w-full rounded-lg p-2 bg-button-bg hover:bg-button-hover-color text-white"
              onClick={createFootprint}
            >
              Start
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup_Card;