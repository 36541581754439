import React, { useState } from "react";
import Footprint_Save_Card from "../Card/Footprint_Save_Card";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../../config";
const Footprint_Save = () => {
  const [topSection, setTopSection] = useState(false);
  const { footprintId, websiteId } = useParams();
  const location = useLocation();
  const { state } = location;


  // console.log("state from footprint save",state.status)

  const handleTopSection = () => {
    setTopSection(true);
  };

  const handleDelete = async () => {
    if (state && state.Back === false) {
      return;
    }
    if (footprintId) {
      try {
        const response = await axios.delete(
          `${baseUrl}/footprint/${footprintId}`
        );
        console.log("response from footprint by id", response.data);
      } catch (error) {
        console.log("Error in fetching data", error.response);
      }
    }
  };
  return (
    <>
      <div className="header-section flex justify-center ">
        <div className="info">
          {!topSection && (
            <div className="guide-info text-color-gray-light flex items-center">
              <Link
                to={`/${websiteId}/footprint-all-list`}
                onClick={handleDelete}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-2"
                >
                  <g clip-path="url(#clip0_7594_235054)">
                    <path
                      d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                      fill="#616161"
                    />
                    <path
                      d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7594_235054">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(8 8)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Link>
              <h2 className=" font-bold text-[24px] mt-2">Back To List</h2>
            </div>
          )}

          <div className="setup-guide mt-5">
            <div className="inner-setup-guide text-color-gray-light">
              <Footprint_Save_Card
                status={state && state.status}
                showSection={handleTopSection}
                footprintId={footprintId}
                websiteId={websiteId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footprint_Save;
