import React from "react";
import Footprint_Info from "../components/Footprint/Footprint_Info";
import Footprint_Info_List from "../components/Footprint/Footprint_Info_list";
import Footprint_Status_List from "../components/Footprint/Footprint_Status_List";
import Footprint_All_List from "../components/Footprint/Footprint_All_List";
import Footprint_Landing from "../components/Footprint/Footprint_Landing";

const FootprintInfoPage = () => {
  return (
    <>
      <div className="guides">
        <div className="inner-guides">
          <div className="">
            {/* <Footprint_Info/> */}
            {/* <Footprint_Info_List/> */}
            {/* <Footprint_Status_List/> */}
            {/* <Footprint_All_List/> */}
            <Footprint_Landing />
          </div>
        </div>
      </div>
    </>
  );
};

export default FootprintInfoPage;
