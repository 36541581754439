import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  matchPath,
} from "react-router-dom";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Login";
import Register from "./components/Register";
import ResetPassword from "./components/ResetPassword";
import ChangePassword from "./components/ChangePassword";
import DashboardSites from "./components/DashboardSites";
import DashboardFootprint from "./components/DashboardFootprint";
import PrivateRoute from "./components/PrivateRoute";
import Video from "./components/Video";
import RedirectManager from "./components/RedirectManager";
import Form from "./components/Onboarding/Form";
import Account from "./components/Settings/Account";
import Notification from "./components/Settings/Notification";
import Sidebar from "./components/Settings/Sidebar";
import Setting from "./components/Settings/Setting";
import FailToast from "./components/Toast/FailToast";
// import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import Layout from "./components/Layout/Layout";
import Guide1 from "./components/Guide/Guide1";
import FootprintHomePage from "./pages/FootprintHomePage";
import FootprintInfoPage from "./pages/FootprintInfoPage";
import { pathToRegexp } from "path-to-regexp";
import FootprintStatusListPage from "./pages/FootprintStatusListPage";
import Footprint_Info_List from "./components/Footprint/Footprint_Info_list";
import Footprint_Status_List from "./components/Footprint/Footprint_Status_List";
import Footprint_All_List from "./components/Footprint/Footprint_All_List";
import Popup_End_Card from "./components/Card/Popup_End_Card";
import SuccessFootprint from "./components/SuccessFootprint";
import Footprint_Guide from "./components/Guide/Footprint_Guide";
import Footprint_Landing_Card from "./components/Card/Footprint_Landing_Card";
import FailedVideo from "./components/FailedVideo";
import Setting_Guide from "./components/Guide/Setting_Guide";
import { Navigate } from "react-router-dom";
import Footprint_Save from "./components/Footprint/Footprint_Save";
import Footprint_Schedule from "./components/Footprint/Footprint_Schedule";
import { VideoLogs } from "./components/VideoLogs";
import VideoDesign from "./components/VideoDesign";
import TagManager from "react-gtm-module";
import ExtensionCard from "./components/Card/ExtensionCard";
import AddWebsitePage from "./pages/AddWebsitePage";
import ResetExpireLink from "./pages/ResetExpireLink";
const LayoutRoutes = () => (
  <Layout>
    <Routes>
      <Route
        path="/:id?/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/setting"
        element={
          <PrivateRoute>
            <Setting_Guide />
          </PrivateRoute>
        }
      />
      <Route
        path="/footprint"
        element={
          <PrivateRoute>
            <FootprintHomePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/guide"
        element={
          <PrivateRoute>
            <Guide1 />
          </PrivateRoute>
        }
      />
      <Route
        path="footprint-list"                // why ??? / is missing
        element={
          <PrivateRoute>
            <FootprintStatusListPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/footprint-info"
        element={
          <PrivateRoute>
            <FootprintInfoPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/footprint-info-list"
        element={
          <PrivateRoute>
            <Footprint_Info_List />
          </PrivateRoute>
        }
      />

      <Route
        path="/footprint-status-list"
        element={
          <PrivateRoute>
            <Footprint_Status_List />
          </PrivateRoute>
        }
      />

      <Route
        path="/:websiteId/footprint-all-list"
        element={
          <PrivateRoute>
            <Footprint_All_List />
          </PrivateRoute>
        }
      />
      <Route
        path="/website/:websiteId/footprint/:footprintId/video/:videoId"
        element={
          <PrivateRoute>
            {/* <Video /> */}
            <FailedVideo />
          </PrivateRoute>
        }
      />

      <Route
        path="/footprint/:footprintId/website/:websiteId"
        element={
          <PrivateRoute>
            <Footprint_Schedule />
          </PrivateRoute>
        }
      />
      <Route
        path="/footprint/:id/:websiteName"
        element={
          <PrivateRoute>
            <Video />
          </PrivateRoute>
        }
      />
      <Route
        path="/successFootprintPopup"
        element={
          <PrivateRoute>
            <SuccessFootprint />
          </PrivateRoute>
        }
      />

      <Route
        path="/onboardSchedule"
        element={
          <PrivateRoute>
            <Footprint_Guide />
          </PrivateRoute>
        }
      />

      <Route
        path="/footprintLanding"
        element={
          <PrivateRoute>
            <Footprint_Landing_Card />
          </PrivateRoute>
        }
      />

      <Route
        path="/Savefootprint/:footprintId/website/:websiteId"
        element={
          <PrivateRoute>
            <Footprint_Save />
          </PrivateRoute>
        }
      />

      {/* <Route
        path="/logs"
        element={
          <PrivateRoute>
            <VideoLogs />
          </PrivateRoute>
        }
      /> */}

      <Route
        path="/logs"
        element={
          <PrivateRoute>
            <VideoDesign />
          </PrivateRoute>
        }
      />
      <Route
        path="/card"
        element={
          <ExtensionCard />
        }
      />

      <Route
        path="/website/:websiteId/footprint/:footprintId/video/:videoId"
        element={
          <PrivateRoute>
            {/* <Video /> */}
            <FailedVideo />
          </PrivateRoute>
        }
      />
      <Route
        path="/addWebsite"
        element={
          <PrivateRoute>
            <AddWebsitePage />
          </PrivateRoute>
        }
      />
    </Routes>
  </Layout>
);

const NoLayoutRoutes = () => (
  <Routes>
    <Route path="/signUp" element={<Register />} />
    <Route path="/onboarding" element={<Form />} />
    <Route path="/signIn" element={<Login />} />
    <Route path="/reset-password" element={<ResetPassword />} />
    <Route path="/user/reset/password/:token" element={<ChangePassword />} />
    <Route path="/reset-expire-link" element={<ResetExpireLink />} />
    <Route path="/" element={<Navigate to="/signIn" />} />
  </Routes>
);



const AppRoutes = () => {
  const location = useLocation();
  const noLayoutRoutes = [
    "/",
    "/signUp",
    "/signIn",
    "/onboarding",
    "/reset-password",
    "/user/reset/password/:token",
  ];



  const isNoLayoutRoute = noLayoutRoutes.some((route) => {
    const regexp = pathToRegexp(route);
    return regexp.test(location.pathname);
  });



  return isNoLayoutRoute ? <NoLayoutRoutes /> : <LayoutRoutes />;
};

// const App = () => (

//   <BrowserRouter>
//     <AppRoutes />
//   </BrowserRouter>
// );

const App = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-MGMH4WN8",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <BrowserRouter>
      <AppRoutes />
    
      {/* <Form/> */}
    </BrowserRouter>
  );
};

export default App;
