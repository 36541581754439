import React, { useState, useEffect } from "react";
import Status_Small_Card from "../Card/Status_Smalll_Card";
import Footprint_Info_Card from "../Card/Footprint_Info_Card";
import Footprint_Status_Card from "../Card/Footprint_Status_Card";
import Footprint_Filter_Card from "../Card/Footprint_Filter_Card";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../config";

const Footprint_Info_List = () => {
  const location = useLocation();
  const { footprintId, websiteId } = location.state || {};

  const [footprint, setFootprint] = useState();
  const [formData, setFormData] = useState({
    footprintName: "",
    tags: "",
    tagsArray:[]
  });

  const fetchFootprintById = async () => {
    if (footprintId) {
      try {
        const response = await axios.get(
          `${baseUrl}/footprint/foot/${footprintId}`
        );
        console.log("response from footprint by id", response.data);
        setFootprint(response.data);
      } catch (error) {
        console.log("Error in fetching data", error.response);
      }
    }
  };

  useEffect(() => {
    fetchFootprintById();
  }, [footprintId]);

  useEffect(() => {
    if (footprint) {
      let Tags = footprint?.tags;
      let footprintTags = Tags?.join(", ");
      setFormData({
        footprintName: footprint?.name || "",
        tags: footprintTags || "",
        tagsArray:footprint?.tagsArray ||[]
      });
    }
  }, [footprintId, footprint]);
  return (
    <>
      <div className="header-section">
        <div className="info">
          <div className="guide-info text-color-gray-light">
            <h2 className=" font-bold text-[24px] mt-2">
              Test report for {footprint?.name}{" "}
            </h2>
          </div>
          <div className="setup-guide mt-5 flex justify-between">
            <div className="inner-setup-guide flex flex-col gap-6 text-color-gray-light w-8/12">
              <Footprint_Filter_Card
                footprintId={footprintId}
                websiteId={websiteId}
              />
            </div>
            <div className="right-inner-setup-guide text-color-gray-light w-4/12 flex flex-col gap-4">
              <Status_Small_Card footprintId={footprintId} />
              <Footprint_Info_Card
                footprintId={footprintId}
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footprint_Info_List;
