
import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";


const Layout = ({ children }) => {
  

 
  return (
    <div className="flex flex-col min-h-screen bg-[#181818]">
      {/* Navbar at the top */}
      <Navbar />

      {/* Content area with sidebar and main content */}
      <div className="flex flex-1 overflow-hidden ">
        {/* Sidebar with fixed width */}
        <Sidebar className="w-64" />

        {/* Main content with flexible width, avoid overflow p-4 removed 29/10 */}
        <main className="flex-1 p-4  ml-64 bg-white  overflow-y-auto rounded-tr-xl">
          {/* <ExtensionAlert/> */}
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
