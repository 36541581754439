// import React, { useState, useEffect, useRef } from "react";

// function CustomSelect({ onChange }) {
//   const options = [
//     { id: "auth", label: "Authentication" },
//     { id: "file", label: "File Upload" },
//     { id: "login", label: "Login" },
//   ];

//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const dropdownRef = useRef(null);

//   const handleCheckboxChange = (optionId) => {
//     setSelectedOptions((prev) => {
//       const newSelection = prev.includes(optionId)
//         ? prev.filter((id) => id !== optionId)
//         : [...prev, optionId];
//       if (onChange) onChange(newSelection); // Notify parent of change
//       console.log("new selection hu",newSelection)
//       return newSelection;
//     });
//   };

//   const handleDropdownToggle = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   const handleClickOutside = (event) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//       setIsDropdownOpen(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const selectedLabel =
//     selectedOptions.length > 0
//       ? `${selectedOptions.length} items selected`
//       : "";

//   return (
   

//     <div className="relative w-full text-xs" ref={dropdownRef}>
//       <div
//         onClick={handleDropdownToggle}
//         className="flex justify-between items-center w-full px-2 py-2 rounded-md border border-[#A1A1A1] focus:outline-none  cursor-pointer"
//       >
//         <span className="text-black font-semibold">{selectedLabel}</span>
//         {/* Dropdown Icon */}
//         <svg
//           className={`w-3 h-3 transition-transform transform ${
//             isDropdownOpen ? "rotate-180" : ""
//           }`}
//           fill="none"
//           stroke="currentColor"
//           viewBox="0 0 24 24"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M19 9l-7 7-7-7"
//           ></path>
//         </svg>
//       </div>
//       <label
//         htmlFor="floatingStatus"
//         className={`absolute text-gray-500 mt-[-7px] duration-300 transform -translate-y-3 scale-75 top-1.5 z-10 origin-[0] bg-white px-2 left-2 pointer-events-none ${
//           selectedOptions.length > 0 || isDropdownOpen
//             ? "scale-75 -translate-y-3"
//             : "scale-100 translate-y-1/2"
//         }`}
//       >
//         Tags
//       </label>
//       {isDropdownOpen && (
//         <div className="absolute w-full bg-white border border-[#A1A1A1] mt-1 rounded-md z-10">
//           {options.map((option) => (
//             <label
//               key={option.id}
//               className={`flex items-center px-2 py-1 cursor-pointer hover:bg-gray-100 ${
//                 selectedOptions.includes(option.id)
//                   ? "text-black font-semibold"
//                   : "text-black"
//               }`}
//             >
//               <input
//                 type="checkbox"
//                 checked={selectedOptions.includes(option.id)}
//                 onChange={() => handleCheckboxChange(option.id)}
//                 className="mr-2 form-checkbox h-3 w-3 text-black font-semibold rounded focus:ring-0 focus:outline-none"
//               />
//               {option.label}
//             </label>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }

// export default CustomSelect;



// try
import React, { useState, useEffect, useRef } from "react";
import { baseUrl } from "../../config";
import axios from 'axios';
import './CustomSelect.css'; // Import custom CSS for checkbox styling

function CustomSelect({ onChange, websiteId }) {
  const [tags, setAllTags] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const getAllTagsOfWebsite = async () => {
    const response = await axios.get(`${baseUrl}/footprint/tags/${websiteId}`);
    if (response.data) {
      setAllTags(response?.data?.tags);
    }
  };

  useEffect(() => {
    getAllTagsOfWebsite();
  }, [websiteId]);

  const handleCheckboxChange = (tag) => {
    setSelectedOptions((prev) => {
      const newSelection = prev.includes(tag)
        ? prev.filter((selectedTag) => selectedTag !== tag)
        : [...prev, tag];
      if (onChange) onChange(newSelection); // Notify parent of change
      return newSelection;
    });
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedLabel =
    selectedOptions.length > 0
      ? `${selectedOptions.length} items selected`
      : "";

  return (
    <div className="relative w-full text-xs" ref={dropdownRef}>
      <div
        onClick={handleDropdownToggle}
        className="flex justify-between items-center w-full px-2 py-2 rounded-md border border-[#A1A1A1] focus:outline-none cursor-pointer"
      >
        <span className="text-black font-semibold">{selectedLabel}</span>
        <svg
          className={`w-3 h-3 transition-transform transform ${
            isDropdownOpen ? "rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </div>
      <label
        htmlFor="floatingStatus"
        className={`absolute text-gray-500 mt-[-7px] text-[16px] duration-300 transform -translate-y-3 scale-75 top-1.5 z-10 origin-[0] bg-white px-2 left-2 pointer-events-none ${
          selectedOptions.length > 0 || isDropdownOpen
            ? "scale-75 -translate-y-2"
            : "scale-100 translate-y-1/2"
        }`}
      >
        Tags
      </label>
      {isDropdownOpen && (
        <div className="absolute w-full bg-white border border-borderColor mt-1 rounded-md z-10 max-h-[7rem]  overflow-y-auto new-scrollbar ">
          {tags.map((tag, index) => (
            <label
              key={index}
              className={`flex items-center px-4 py-2.5  cursor-pointer hover:bg-gray-100  ${
                selectedOptions.includes(tag)
                  ? "text-black font-semibold"
                  : "text-black"
              }`}
            >
              <input
                type="checkbox"
                checked={selectedOptions.includes(tag)}
                onChange={() => handleCheckboxChange(tag)}
                className="mr-2 custom-checkbox form-checkbox h-4 w-4 text-[16px] text-black font-semibold rounded focus:ring-0 focus:outline-none"
              />
              {tag}
            </label>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomSelect;






