import React from "react";
import Status_Small_Card from "../Card/Status_Smalll_Card";
import Footprint_Info_Card from "../Card/Footprint_Info_Card";
import Footprint_Status_Card from "../Card/Footprint_Status_Card";
import Footprint_List_Status_Card from "../Card/Footprint_List_Status_Card";
import { useLocation } from "react-router-dom";
const Footprint_Status_List = () => {
  const location = useLocation();

  const { websiteId } = location.state || {};

  return (
    <>
      <div className="header-section">
        <div className="info">
          {/* <div className="guide-info text-color-gray-light">
            <h2 className=" font-bold text-[24px] mt-2">Foot Prints</h2>
          </div> */}
          <div className="setup-guide mt-5 flex justify-between">
            <div className="inner-setup-guide flex flex-col gap-6 text-color-gray-light w-full">
              <Footprint_List_Status_Card websiteId={websiteId} />
              {/* <Footprint_List_Status_Card /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footprint_Status_List;
