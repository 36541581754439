import React, { useState, useEffect } from "react";
import axios from "axios";
import FloatingInput from "../Input/FloatingInput";
import { baseUrl } from "../../config";
import Intercom from "@intercom/messenger-js-sdk";
import Popup_Card from "./Popup_Card";
const Footprint_Home_Card = ({ website_Id }) => {

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState();
  const [checked, setChecked] = useState(false);

  // Determine which URL to use based on user role
  const [user, setUser] = useState();



  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };
  const [formData, setFormData] = useState({
    footprintName: "",
    footprintUrl: "",
    tags: "",
    websiteId:  website_Id,
    tagsArray: [],
  });

  const [showPop, setShowPopup] = useState(false);

  const handlePopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const urlToSend = user?.role === "admin" ? formData?.footprintUrl : websiteUrl;
  console.log("WebsiteId passed in footprintSaveCard",formData.websiteId)
  useEffect(() => {
    const messageListener = (event) => {
      if (event.source !== window) return;
      if (event.data.type && event.data.type === "FROM_EXTENSION") {
        console.log("Message from extension:", event.data.data);
        // Handle the message from the extension if needed
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);








  const checkPopUp = async () => {
    try {
  
        console.log("mai pop up hu",checked)
      // Check response and handle accordingly
      if (checked) {
        createFootprint();

      } else {
        handlePopup();

      }
    } catch (error) {
      console.error("Error updating popup status:", error);
    }
  };





  const createFootprint = async () => {
    // console.log("mai data hu ", formData);
    const token = localStorage.getItem("Token");
    const userId = localStorage.getItem("userId");
    // Send message to content script to start tracking
    window.postMessage(
      {
        type: "START_TRACKING",
        url: formData.footprintUrl,
        userId: userId,
        websiteId: formData.websiteId,
        footprintName: formData.footprintName,
        tags: formData.tags,
        websiteUrl: urlToSend,
        tagsArray: formData.tagsArray,
      },
      "*"
    );

    console.log(
      "Start tracking message sent to extension",
      formData,
      websiteUrl
    );
  };

  const fetchUserWebsite = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(`${baseUrl}/user/${userId}`);
      setUser(response.data);
      // const website = response.data?.websites;
      // const lastWebsite = website[website.length - 1];
      // setFormData((prevFormData) => ({
      //   ...prevFormData,
      //   websiteId: lastWebsite,
      // }));
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const fetchWebsiteByWebsiteId = async () => {
    // const userId = localStorage.getItem("userId");
    try {
      // const response = await axios.get(`${baseUrl}/websites/user/${userId}`);
      // console.log(
      //   "fetch user website for extension",
      //   response.data.websites[0].url
      // );
      // setWebsiteUrl(response.data?.websites[0].url);
      const response = await axios.get(`${baseUrl}/websites/${website_Id}`);
      console.log("response from website", response?.data);
      setWebsiteUrl(
        response?.data?.url
      );
      setChecked(response?.data.showPopUp);

    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  // alternative for fetchWebsiteByUserId

  // const checkUserWebsite = async () => {
  //   try {

  //     const storedWebsiteId = localStorage.getItem("latestWebsiteId");

  //     if (storedWebsiteId) {
  //       const response = await axios.get(`${baseUrl}/websites/${storedWebsiteId}`);
  //       console.log("response from website", response.data?.websites);
  //       setWebsiteId(
  //         response?.data ? response.data?._id : ""
  //       );
  //       setGuide3(true);
  //       showSection(true);

  //     }


  //   } catch (error) {
  //     // alert(error.response.data.msg);
  //   }
  // };

  useEffect(() => {
    fetchUserWebsite();
    fetchWebsiteByWebsiteId();
  }, []);

  // useEffect(() => {
  //   const isValid = formData.footprintUrl.includes(websiteUrl);
  //   setIsButtonEnabled(isValid);
  // }, [websiteUrl, formData.footprintUrl]);

  useEffect(() => {
    console.log("admin", user)
    if (user?.role === "admin") {

      setIsButtonEnabled(true);
    } else {
      const isValid = formData.footprintUrl.includes(websiteUrl);
      console.log("checking website valied", isValid)
      setIsButtonEnabled(isValid);
    }
  }, [websiteUrl, formData.footprintUrl, user]);

  return (
    <>
      <div className="container ">
        {/** h-[55vh] removed */}
        <div className="wrapper  w-[50vw]  border border-[#E0E0E0] rounded-xl bg-white my-2">
          <div className="top-section flex justify-between items-center mx-4 my-4">
            <div className="content text-color-gray-light font-bold  text-xl ">
              <h3 className="text-lg">New Test</h3>
            </div>
          </div>

          <FloatingInput
            formData={formData}
            setFormData={setFormData}
            isEditable={true}
            isReadOnly={false}
            isButtonEnabled={isButtonEnabled}
          />

          {showPop && (
            <>
              <div className="fixed inset-0 bg-black opacity-50 z-40"></div>{" "}
              {/* Overlay */}
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <Popup_Card
                  formData={formData}
                  onClose={handleClosePopup}
                  urlToSend={urlToSend}
                />
              </div>
            </>
          )}

          <div className="p-1">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                {/* my-2 */}
                <div className="ml-3 flex  items-center gap-3     mt-2 mb-4">
                  <button
                    id="button_create_test"
                    className={`button text-xs rounded-lg p-2 flex items-center space-x-2 hover:bg-button-hover-color mt-2 ${isButtonEnabled
                      ? "bg-button-bg text-white"
                      : "bg-button-disable text-[#C0C0C0] border-[#C5C5C5] "
                      }`}
                    onClick={checkPopUp}
                    disabled={!isButtonEnabled}
                  >
                    <span>Create Test</span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.6401 7.02539H12.0186V5.02539H19.0186V12.0254H17.0186V8.4754L12.1046 13.3893L10.6904 11.9751L15.6401 7.02539Z"
                        fill="#C0C0C0"
                      />
                      <path
                        d="M10.9824 6.97485H4.98242V18.9749H16.9824V12.9749H14.9824V16.9749H6.98242V8.97485H10.9824V6.97485Z"
                        fill="#C0C0C0"
                      />
                    </svg>
                  </button>
                  <a id="help" className="mt-1">
                    <span
                      className="text-xs mt-2 text-black cursor-pointer"
                      onClick={() => Intercom("showNewMessage")}
                    >
                      Connect with us for Help
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {footPrintSaveCard && (
        <Footprint_Save
          footprintId={footprintId}
          websiteId={formData.websiteId}
        />
      )} */}

    </>
  );
};

export default Footprint_Home_Card;
