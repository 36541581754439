import React from "react";
import { IoGlobeOutline, IoAdd } from "react-icons/io5";
import { IoIosLogOut } from "react-icons/io";
import { CiHome } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Sidebar = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    // localStorage.clear();
    // localStorage.removeItem("username");
    // localStorage.removeItem("userId");
    // localStorage.removeItem("Token");
    console.log("Before removal:", Object.keys(localStorage));
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
    localStorage.removeItem("Token");
    console.log("After removal:", Object.keys(localStorage));
    navigate("/login");
  };
  return (
    <div className="bg-black flex flex-col text-gray-900 h-screen px-4 fixed w-16 md:w-64 border-r border-gray-300 dark:border-gray-600 dark:bg-gray-900 dark:text-white">
      <div className="flex justify-center  mt-3">
        <Link to={"/dashboardSites"}>
          <img
            src="https://moretasks.com/images/morelogo.png"
            alt="moretasks"
            width={150}
          />
        </Link>
      </div>

      <ul className="flex flex-col mt-5 text-xl">
        <li
          className="flex items-center py-2 px-2 space-x-4 text-white text-lg hover:rounded hover:cursor-pointer 
        hover:bg-gray-600 hover:text-white"
        >
          <CiHome size={20} />
          <span className="hidden md:inline">
            <Link to={"/dashboardSites"}>Home</Link>
          </span>
        </li>
        {/* <li
          className="flex items-center py-2 px-2 space-x-4 text-white text-lg hover:rounded hover:cursor-pointer 
        hover:text-white hover:bg-gray-600"
        >
          <IoAdd size={20} />
          <span className="hidden md:inline">
            <Link to={"/dashboardFootprint"}>Add Footprint</Link>
          </span>
        </li> */}

        <li
          className="flex mt-auto items-center py-2 px-2 space-x-4  text-white text-lg hover:rounded hover:cursor-pointer 
        hover:text-white hover:bg-red-600"
        >
          <IoIosLogOut size={20} />
          <span className="hidden md:inline" onClick={handleLogout}>
            Logout
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
