import axios from "axios";
import React, { useState } from "react";
import { baseUrl } from "../../config";

const OtpModal = ({ show, phoneNumber, onClose }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const handleChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = async () => {
    const otpCode = otp.join("");
    const userId = localStorage.getItem("userId");
    let phone = "+" + phoneNumber;

    try {
      const response = await axios.post(`${baseUrl}/verify/verify-phone`, {
        phoneNumber: phone,
        code: otpCode,
        userId: userId,
      });
      console.log("response from Otp verify ", response.data);
      onClose();
    } catch (error) {
      console.log("Error in fetching data", error.response);
      alert(error.response.data.message);
      onClose();
    }
  };

  if (!show) return null;
  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center backdrop-blur">
      <div className="w-full max-w-md px-8 py-10 bg-white rounded-lg shadow-md flex flex-col justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-center mb-6">Enter OTP</h1>
          <p className="text-gray-600 text-center mb-4">
            Code sent to {"+" + phoneNumber}
          </p>
          <form
            // onSubmit={handleSubmit}
            className="grid grid-cols-6 gap-x-2 my-2"
          >
            {otp.map((value, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                type="text"
                maxLength="1"
                value={value}
                onChange={(e) => handleChange(e.target, index)}
                className="rounded-lg bg-gray-100 w-14 aspect-square text-gray-700 text-center"
              />
            ))}
          </form>
        </div>
        <div className="flex items-center flex-col justify-between mb-6">
          <p className="text-gray-600 text-sm">Didn't receive code?</p>
          <div className="flex items-center space-x-2">
            <button className="px-3 py-2 text-sm font-medium text-center rounded text-gray-500 hover:text-blue-500">
              Request via Call
            </button>
            <button className="px-3 py-2 text-sm font-medium text-center rounded text-gray-500 hover:text-blue-500">
              Request Again (00:00:36)
            </button>
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          className="w-full px-4 py-2 mt-auto text-lg font-medium text-white bg-button-bg rounded-md "
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default OtpModal;
