import React, { useState } from "react";
import TableList from "../Table/TableList";

const Footprint_Filter_Card = ({ footprintId, websiteId }) => {
  const [filter, setFilter] = useState("All");

  const handleFilterClick = (status) => {
    setFilter(status);
  };

  return (
    <div className="container">
      <div className="wrapper w-[50vw] h-auto min-h-[58vh] border rounded-xl flex flex-col bg-white">
        <div className="top-section flex justify-between items-center mx-4 mt-4">
          <div className="content text-color-gray-light font-semibold text-xl">
            <h3 className="text-lg">Status</h3>
          </div>
          <div className="filter-items">
            <div className="items flex justify-between border border-[#E0E0E0] items-center gap-2 space-x-4 rounded-lg">
              <div
                className={`item-1 rounded-lg px-2 py-1 m-1 cursor-pointer ${
                  filter === "All" ? "bg-[#E5E5E5]" : ""
                }`}
                onClick={() => handleFilterClick("All")}
              >
                All
              </div>
              <div
                className={`item-2 rounded-lg px-2 py-1 cursor-pointer ${
                  filter === "Fail" ? "bg-[#E5E5E5]" : ""
                }`}
                onClick={() => handleFilterClick("Fail")}
              >
                Failed
              </div>
              <div
                className={`item-3 rounded-lg px-2 py-1 cursor-pointer ${
                  filter === "Pass" ? "bg-[#E5E5E5]" : ""
                }`}
                onClick={() => handleFilterClick("Pass")}
              >
                Pass
              </div>
            </div>
          </div>
        </div>

        <div className="flex-grow">
          <TableList
            filter={filter}
            footprintId={footprintId}
            websiteId={websiteId}
          />
        </div>
      </div>
    </div>
  );
};

export default Footprint_Filter_Card;
