import React from "react";

import Footprint_Home from "../components/Footprint/Footprint_Home";
import Footprint_Save from "../components/Footprint/Footprint_Save";
import Footprint_Schedule from "../components/Footprint/Footprint_Schedule";
import Footprint_Info from "../components/Footprint/Footprint_Info";
import Footprint_Landing from "../components/Footprint/Footprint_Landing";

const FootprintHomePage = ({website_Id}) => {
  return (
    <>
      <div className="guides">
        <div className="inner-guides">
          {/** removed w-[60vw] */}
          <div className="container flex justify-center h-[50vh] ">
            <Footprint_Home website_Id={website_Id} />
            {/* <Footprint_Save/> */}
            {/* <Footprint_Schedule/> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FootprintHomePage;
