import React from "react";
import Footprint_Guide_Card from "../Card/Footprint_Guide_Card";
import { useLocation } from "react-router-dom";
const Footprint_Guide = () => {
  // websiteId: websiteId, footprintId: footprintId
  const location = useLocation();
  const { websiteId, footprintId } = location.state || {};
  return (
    <>
      <div className="header-section">
        <div className="info">
          <div className="guide-info flex text-color-gray-light">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_7594_235054)">
                <path
                  d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                  fill="#616161"
                />
                <path
                  d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                  fill="black"
                  fill-opacity="0.2"
                />
              </g>
              <defs>
                <clipPath id="clip0_7594_235054">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(8 8)"
                  />
                </clipPath>
              </defs>
            </svg>

            <h2 className=" font-bold text-[24px]">Create Foot Print</h2>
          </div>
          <div className="guide-info text-color-gray-light">
            {/* <h2 className=" font-bold text-[24px]">
              Get ready to setup your website and add your footprints to it
            </h2> */}
            {/* <p className="text-xs">
              We’ll hand hold you through the process of adding your website and
              then setting up your test.
            </p> */}
          </div>

          <div className="setup-guide mt-5">
            <div className="inner-setup-guide text-color-gray-light">
              <Footprint_Guide_Card
                websiteId={websiteId}
                footprintId={footprintId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footprint_Guide;
