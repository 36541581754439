import { Fragment, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../config";
const AddSite = ({ isOpen, closeModal }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    websiteName: "",
    url: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);

    const token = localStorage.getItem("Token");
    console.log(token,"trt ibg--------------------")

    if (token) {
      try {
        const response = await axios.post(
          `${baseUrl}/websites/`,
          formData,
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );
        console.log("response", response.data);
        // alert(response.data.msg);
        toast.success(response.data.msg);
        window.location.reload();
      } catch (error) {
        alert(error.response.data.msg);
      }
    } else {
      alert("Please Login");
      navigate("/login");
    }

    closeModal();
  };
  return (
    <Fragment>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="mt-1">
              <h2 className="text-lg font-bold mb-2 text-center">
                Add Website
              </h2>

              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="username"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Website Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="websiteName"
                    className="block w-full px-3 py-2 border rounded bg-gray-200 focus:outline-none focus:bg-white"
                    placeholder="Enter your website name"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">
                    Website URL
                  </label>
                  <input
                    type="text"
                    id="text"
                    name="url"
                    className="block w-full px-3 py-2 border rounded bg-gray-200 focus:outline-none focus:bg-white"
                    placeholder="Enter your website url"
                    required
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-black hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AddSite;
