import React, { useEffect, useState } from "react";
import Footprint_Status_Table from "../Table/Footprint_Status_Table";
import CustomSelect from "../Input/CustomSelect";
import CustomSelectStatus from "../Input/CustomSelectStatus";
import { Link } from "react-router-dom";

const Footprint_List_Status_Card = ({ websiteId }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const handleSelectChange = (newSelection) => {
    setSelectedValues(newSelection);
  };

  const handleStatusChange = (newSelection) => {
    setSelectedStatus(newSelection);
  };

  useEffect(() => {
    console.log(selectedValues, "nieiie");
  }, [selectedValues]);

  return (
    <div className="container">
      <div className="wrapper w-full h-auto min-h-[58vh] border rounded-xl flex flex-col bg-white">
        <div className="top-section flex justify-between items-center mx-4 mt-4">
          {/* Left Side Content */}
          <div className="content text-black font-bold text-xl">
            <h3 className="text-lg">Test Reports</h3>
          </div>

          {/* Right Side Content */}
          <div className="mid-section flex space-x-4">
            <div className="relative w-48 text-sm">
              <CustomSelect onChange={handleSelectChange} websiteId={websiteId} />
            </div>

            {/* Second Custom Select */}
            <div className="relative w-48 text-sm">
              <CustomSelectStatus onChange={handleStatusChange} />
            </div>

            <div className="relative text-sm">
              <Link to="/dashboard">
                <button className="button bg-button-bg hover:bg-button-hover-color text-white text-xs rounded-md p-1.5 flex items-center">
                  <span>Create Test</span>
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex-grow">
          <Footprint_Status_Table
            websiteId={websiteId}
            selectedTags={selectedValues}
            selectedStatus={selectedStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default Footprint_List_Status_Card;
