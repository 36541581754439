import React, { useEffect, useState } from "react";
import Footprint_Guide_Card from "../Card/Footprint_Guide_Card";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../config";
import Setting from "../Settings/Setting";
const Setting_Guide = () => {
  const userId = localStorage.getItem("userId");
  const [website, setWebsites] = useState();
  const [selectedWebsite, setSelectedWebsite] = useState(null);

  // const fetchWebsiteByUserId = async () => {
  //   try {
  //     const response = await axios.get(`${baseUrl}/websites/user/${userId}`);
  //     console.log(
  //       "fetch user website from sidebarrrrrr",
  //       response.data.websites.at(-1)
  //     );
  //     setWebsite(response.data.websites.at(-1));

  //   } catch (error) {
  //     console.log("Error in fetching data from sidebar", error.response);
  //   }
  // };

  const fetchWebsites = async () => {
    try {
      const response = await axios.get(`${baseUrl}/websites/user/${userId}`);
      console.log("response from fetchwebsite", response.data.websites);
      setWebsites(response.data.websites);


      // Retrieve the last selected website ID from local storage
      const storedWebsiteId = localStorage.getItem('selectedWebsiteId');
      if (storedWebsiteId) {
        // Find and set the previously selected website
        const storedWebsite = response.data.websites.find(
          (website) => website._id === storedWebsiteId
        );
        if (storedWebsite) {
          setSelectedWebsite(storedWebsite);
        } else {
          // If not found, default to the last website
          setSelectedWebsite(response.data.websites.at(-1));
        }
      } else {
        // Default to the last website if no previous selection is stored
        setSelectedWebsite(response.data.websites.at(-1));
      }
    } catch (error) {
      console.log('Error fetching websites:', error.response);
    }
  };


  useEffect(() => {
    if (userId) {
      fetchWebsites()
    }
  }, [userId]);


  return (
    <>
      <div className="header-section">
        <div className="info">
          <div className="guide-info flex text-color-gray-light mx-44">
            {website?.length > 0 && (
              <Link to={`/${selectedWebsite?._id}/footprint-all-list`}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7594_235054)">
                    <path
                      d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                      fill="#616161"
                    />
                    <path
                      d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7594_235054">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(8 8)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Link>)}

            <h2 className=" font-semibold text-[24px] ">Settings</h2>
          </div>

          <div className="setup-guide mt-5">
            <div className="inner-setup-guide text-color-gray-light">
              <Setting />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting_Guide;
