import React from 'react'

const ExtensionAlert = () => {
    return (
        <>
            <div className="container">
                <div className="inner-wrapper z-50 flex justify-between items-center h-[56px] bg-[#FCD0CF] border border-[#FAA09E] border-extension-alert rounded-lg py-4 px-4">
                    <div className="left-content flex justify-between items-center gap-4">
                        <div className="items">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 10.9794C11 10.4271 11.4477 9.97937 12 9.97937C12.5523 9.97937 13 10.4271 13 10.9794V16.9794C13 17.5317 12.5523 17.9794 12 17.9794C11.4477 17.9794 11 17.5317 11 16.9794V10.9794Z" fill="#F5413D" />
                                <path d="M12 6.05115C11.4477 6.05115 11 6.49886 11 7.05115C11 7.60343 11.4477 8.05115 12 8.05115C12.5523 8.05115 13 7.60343 13 7.05115C13 6.49886 12.5523 6.05115 12 6.05115Z" fill="#F5413D" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z" fill="#F5413D" />
                            </svg>


                        </div>
                        <div className="items flex items-center justify-center gap-2"> <span className='text-[#DA100B] font-bold '>Warning!</span> <p className='warning text-[16px]'>   We have detected that you have not installed the TestTube extension.</p></div>
                    </div>
                    <div className="right-content flex justify-between items-center gap-4">
                        <div className="sub right-items">


                        </div>
                        <div className="sub right-items font-bold">
                            {/* <a href="">
                            Install Extension
                            </a> */}
                            <a
                                className="button text-xs rounded-lg p-2  flex items-center space-x-2 hover:bg-button-hover-color 
                             bg-button-bg text-white"
                                href="https://chromewebstore.google.com/detail/testtube-no-code-qa-autom/pbcljcpjmdhgpffgalkifignmbnedikk"
                                target="_blank"
                            >
                                Install Extension


                            </a>

                        </div>

                        {/* <div className="sub right-items">

                            <div className="close-icon">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.22664 0.811448C1.83611 0.420924 1.20295 0.420924 0.812424 0.811448C0.4219 1.20197 0.4219 1.83514 0.812424 2.22566L6.58716 8.0004L0.812484 13.7751C0.42196 14.1656 0.42196 14.7988 0.812484 15.1893C1.20301 15.5798 1.83617 15.5798 2.2267 15.1893L8.00137 9.41461L13.776 15.1893C14.1666 15.5798 14.7997 15.5798 15.1903 15.1893C15.5808 14.7988 15.5808 14.1656 15.1903 13.7751L9.41559 8.0004L15.1903 2.22566C15.5808 1.83514 15.5808 1.20197 15.1903 0.811448C14.7998 0.420924 14.1666 0.420924 13.7761 0.811448L8.00137 6.58618L2.22664 0.811448Z" fill="#818181" />
                                </svg>
                            </div>

                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExtensionAlert